<span class="ng-select-scrollable-box">
	<ng-select
			class="custom"
			(change)="onValueChanged($event)"
			[(ngModel)]="value"
			[formControl]="_fc"
			[placeholder]="placeholder"
			[clearable]="clearable"
			[multiple]="multiple"
			[items]="_data"
			[bindLabel]="bindLabel"
			[bindValue]="bindValue"
			[groupBy]="'children'"
			[selectableGroup]="false"
			[isOpen]="isOpen"
			[addTag]="allowFreeText ? addTagFn : null"
			[addTagText]="allowFreeText ? addEntityText : ''"
			[appendTo]="appendTo"
			[maxSelectedItems]="select2Options.maximumSelectionLength"
			[searchable]="!disableSearch"
			[hideSelected]="hideSelected"
	>
		<!-- Header buttons -->
		<ng-template ng-header-tmp *ngIf="(showSelectAll || showClearAll) && multiple">
			<div class="row p-3">
				<button class="btn btn-brand btn-bold mr-3" *ngIf="showSelectAll"
					  (click)="onValueChanged([], true)">{{'action.selectAll' | translate}}</button>
				<button class="btn btn-brand btn-bold" *ngIf="showClearAll"
					  (click)="onValueChanged([])">{{'action.clear' | translate}}</button>
			</div>
		</ng-template>

		<!-- Option without template -->
		<ng-template ng-option-tmp let-item="item" *ngIf="!selectionTemplate">
			{{item.text}}
		</ng-template>

		<!-- Option if template -->
		<ng-template ng-option-tmp let-item="item" *ngIf="selectionTemplate">
			<ng-container [ngTemplateOutlet]="selectionTemplate" [ngTemplateOutletContext]="{entry: item}"></ng-container>
		</ng-template>

		<!-- Multiselection result template with Dragula -->
		<ng-template ng-multi-label-tmp let-items="items" let-clear="clear" *ngIf="!resultTemplate && multiple && sortable">
		  <div> <!--  -->
			  <span class="ng-value" *ngFor="let item of items">
				  <span class="ng-value-icon mt-1" (click)="clear(item)"><i class="la la-times"></i></span><!-- (click)="removeSelectedItem(item)-->
				  <span class="ng-value-label">{{item.text}}</span>
			  </span>
		  </div>
		</ng-template>

		<!-- Multiselection result template with Dragula -->
		<ng-template ng-multi-label-tmp let-items="items" let-clear="clear" *ngIf="!resultTemplate && multiple && !sortable">
		  <div> <!--  -->
			  <span class="ng-value" *ngFor="let item of items">
				  <span class="ng-value-icon mt-1" (click)="clear(item)"><i class="la la-times"></i></span><!-- (click)="removeSelectedItem(item)-->
				  <span class="ng-value-label">{{item.text}}</span>
			  </span>
		  </div>
		</ng-template>

		<!-- Single selection result template -->
		<ng-template ng-label-tmp let-item="item" *ngIf="!resultTemplate && !multiple">
			 <span class="ng-value-label">{{item.text}}</span>
		</ng-template>

		<!-- Multiselection result with custom template and dragula -->
		<ng-template  ng-multi-label-tmp let-items="items" let-clear="clear" *ngIf="resultTemplate && multiple && sortable">
			 <ng-container *ngFor="let item of items" [ngTemplateOutlet]="resultTemplate" [ngTemplateOutletContext]="{entry: item}"></ng-container>
		</ng-template>

		<!-- Multiselection result with custom template and without dragula -->
		<ng-template ng-multi-label-tmp let-items="items" let-clear="clear" *ngIf="resultTemplate && multiple && !sortable">
			 <ng-container *ngFor="let item of items" [ngTemplateOutlet]="resultTemplate" [ngTemplateOutletContext]="{entry: item}"></ng-container>
		</ng-template>

		<!-- Single Selection result with custom template -->
		<ng-template ng-label-tmp let-item="item" let-clear="clear" *ngIf="resultTemplate && !multiple">
			 <ng-container [ngTemplateOutlet]="resultTemplate" [ngTemplateOutletContext]="{entry: item}"></ng-container>
		</ng-template>

		<!-- Group Title -->
		<ng-template ng-optgroup-tmp let-item="item">
			<div class="row justify-content-between px-5" style="display: flex; align-items: center;">
				<span style="display: flex; align-items: center;"><b>{{item?.text}}</b></span>
				<button
						*ngIf="allowGroupSelection && multiple"
						class="btn btn-brand btn-bold mr-3"
						(click)="selectAllByGroup(item)"
				>
					{{'action.selectAll' | translate}}
				</button>
			</div>
		</ng-template>
	</ng-select>
	<div *ngIf="isLoading" class="select2-loader"></div>
</span>

<!--		   <ng-option *ngFor="let item of this._data" [value]="''+item.id">{{item.text}}</ng-option>-->
