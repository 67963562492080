import {Inject, Injectable, Optional, PLATFORM_ID} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {REQUEST} from '../../../express.tokens';
import {Request} from 'express';

const isAbsoluteURL = (value) => ['http', '//'].some(test => value.toLowerCase().startsWith(test.toLowerCase()));

@Injectable()
export class AngularUniversalInterceptor implements HttpInterceptor {

	private isServer: boolean;

	constructor(@Optional() @Inject(REQUEST) protected request: Request,
	            @Inject(PLATFORM_ID) private platformId: any) {
		this.isServer = platformId == "server";
	}

	// Make sure this is the last interceptor.

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		if (isAbsoluteURL && this.isServer) {
			request = request.clone({
				url: `${this.request.protocol}://${this.request.get('host')}${request.url}`
			})
		}

		return next.handle(request);
	}
}
