import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CoreComponent} from './core/core.component';
import {JobApplicationComponent} from "./job/job-application/job-application.component";
import {ForbiddenComponent} from "./core/error-pages/forbidden/forbidden.component";
import {NotFoundComponent} from "./core/error-pages/not-found/not-found.component";
import {JobApplicationShortComponent} from "./job/job-application-short/job-application-short.component";
import {CompleteApplicationRouteGuard} from "./shared/guards/complete-application-route.guard";
import {ShortApplicationRouteGuard} from "./shared/guards/short-application-route.guard";

const routes: Routes = [
	{
		path: '',
		component: CoreComponent,
		children: [
			{
				path: 'home',
				loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
			},
			{
				path: 'company',
				loadChildren: () => import('./company/company.module').then(m => m.CompanyModule)
			},
			{
				path: 'job',
				loadChildren: () => import('./job/job.module').then(m => m.JobModule)
			},
			{
				path: 'job-spontaneous',
				component: JobApplicationComponent,
				data: {
					isSpontaneous: true
				},
				canActivate: [CompleteApplicationRouteGuard]
			},
			{
				path: 'job-spontaneous/short',
				component: JobApplicationShortComponent,
				data: {
					isSpontaneous: true
				},
				canActivate: [ShortApplicationRouteGuard]
			},
			{
				path: 'page',
				loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
			},
			{
				path: '404',
				component: NotFoundComponent

			},
			{
				path: '403',
				component: ForbiddenComponent

			},
			{
				path: '',
				pathMatch: 'full',
				redirectTo: 'home',
			}
			// {
			// path: 'personal-data/:personId/:hashCode',
			// component: DeletePersonalDataComponent
			// }
		]
	},
	{path: '**', pathMatch: 'full', redirectTo: '404'},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {useHash: false})],
	exports: [RouterModule],
	providers: []
})
export class AppRoutingModule {
}
