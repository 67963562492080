import {Component, OnInit} from '@angular/core';
import {LayoutService} from "../../../layout.service";
import {IsBrowserService} from "../../../../shared/services/isBrowser.service";


@Component({
	selector: 'app-mobile-header',
	templateUrl: './mobile-header.component.html',
	styleUrls: ['./mobile-header.component.scss']
})
export class MobileHeaderComponent implements OnInit {
	isBrowser: boolean = false;

	constructor(private layoutService: LayoutService,
	            private isBrowserService: IsBrowserService) {
	}

	ngOnInit(): void {
		this.isBrowser = this.isBrowserService.isBrowser;
	}

	toggleAside() {
		this.layoutService.asideOn = !this.layoutService.asideOn;
	}

	toggleTopbar() {
		if (this.isBrowser) document.body.classList.toggle('topbar-mobile-on');
	}

}
