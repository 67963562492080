import {
	AfterContentInit,
	ChangeDetectorRef,
	Component,
	ContentChildren,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Optional,
	Output,
	QueryList,
	Self,
	SimpleChanges,
	TemplateRef,
	ViewEncapsulation
} from '@angular/core';
import {ControlValueAccessor, FormControl, NgControl} from '@angular/forms';
import {Subscription} from 'rxjs';
import {MetOptionGroup, MetOptionGroupComponent} from './met-option-group.component';
import {MetOption, MetOptionComponent} from './met-option.component';
import {TranslateService} from '@ngx-translate/core';
import {NgOption, NgSelectConfig} from '@ng-select/ng-select';
import {Filterable, Mappable, Someable} from "../../interfaces";
// import {DragulaService} from 'ng2-dragula';
import {getRandomId} from "../../functions/objects-utilities";
import _ from "lodash";

declare var jQuery: any;

@Component({
	selector: 'met-select',
	templateUrl: './met-select.component.html',
	styleUrls: ['./met-select.component.scss'],
	providers: [],
	encapsulation: ViewEncapsulation.None
})
export class MetSelectComponent implements AfterContentInit, OnInit, OnChanges, OnDestroy, ControlValueAccessor {

	/** Random string to identify "Select All" option */
	// static readonly SELECT_ALL_ID = 'NK5YylCghpUJJmlcmRmy';
	/** Random prefix to identify tags supplied by the user */
	static readonly NEW_TAG_PREFIX = 'dUH6PAecgLgXLdyL5JSF_';
	// static readonly SELECT_ALL_TRANSLATION = 'action.selectAll';

//  @Input() selectionTemplate: TemplateRef<Select2OptionData>;
	//  @Input() resultTemplate: TemplateRef<Select2OptionData>;
	@Input() selectionTemplate: TemplateRef<any>;//TODO :use NGOption
	@Input() resultTemplate: TemplateRef<any>; //TODO: use NGOption
	/**
	 * If you want to allow selecting multiple values. Default is `false`.
	 *
	 * If this is set to `true`, the value of this component will be an array of all the IDs.
	 * Otherwise, it's the ID of the selected option.
	 */
	@Input() multiple = false;
	/**
	 * The string to use as a placeholder inside the select.
	 */
	@Input() placeholder: string;
	/**
	 * Whether the user can enter arbitrary text.
	 *
	 * If you set this to `true`, you might want to listen to the `createNewTag` event.
	 * If you do not listen to the event, this select will simply be handled as if it was a string array / string value.
	 *
	 * @see createNewTag
	 */
	@Input() allowFreeText = false;
	@Input() addEntityText = 'Add Item';
	@Input() select2Options: any = {}; //legacy
	@Input() selectOptions: any = {}; //TODO merge this with select2Options and other stuff inside ngOnChange()
	@Input() disableSearch = false;
	@Input() showSelectAll = false;
	@Input() showClearAll = false;
	@Input() sortable = false;
	@Input() allowGroupSelection = false;
	@Input() isOpen:boolean; //useful for chip/tag list

	@Input() bindLabel:string ="text";
	@Input() bindValue:string = "id";
	@Input() appendTo:string = null;// 'body';
	@Input() clearable:boolean = false;
	@Input() hideSelected:boolean = false;

	/**
	 * The IDs of options to show at the top of the list in a separate group.
	 */
	@Input() topGroup: string[];
	/**
	 * The name of the group at the top.
	 * @see topGroup
	 */
	@Input() topGroupName: string;
	@Output() change = new EventEmitter<any>();
	/**
	 * Fired to allow you to create a new entry from the user's text input.
	 * It gets the index of the element, the input and the placeholder as a parameter.
	 *
	 * This event only makes sense if you set `allowFreeText` to `true`.
	 * It works as follows:
	 * 1. the user enters text
	 * 2. the text gets prefixed to distinguish it from the other existing values
	 * 3. the `createNewTag` event gets fired
	 * 4. you create the entity (backend call or whatever you want)
	 * 5. you add a `met-option` for the entity and set the met-select value (replacing the placeholder)
	 *
	 * You only need to care about step 4 and 5. Execute them when receiving this event.
	 * @see allowFreeText
	 */
	@Output() createNewTag = new EventEmitter<[number, string, string]>();

	disabled: boolean = false;

	randomId = getRandomId(13);

	value: string | string[];
	@Input() isLoading: boolean = false;

	@ContentChildren(MetOptionGroupComponent) optGroups: QueryList<MetOptionGroupComponent>;
	@ContentChildren(MetOptionComponent) options: QueryList<MetOptionComponent>;

	public _fc = new FormControl();
	private _valueChanges: Subscription;
	private _optionChangeSubs: Subscription[] = [];
	private _onChanged: any;
	private _onTouched: any;

	orderedItems = [];

	/*
     * IMPORTANT: this is commented out for now, because it seems to be fixed and causes problems now
     * Prevents the following bug:
     * Changing `value` results in select2 firing a valueChanged event, which results in this control being marked dirty.
     */
	// private _ignoreNextValueChange = false;

	_data: NgOption[];
	private _topOptions: MetOptionComponent[];
	_selectOptions: any;


	//TODO NOT NEEDED?
	// applyTemplate(state: NgOption, template: TemplateRef<any>): JQuery | string {
	// 	if (!template) {
	// 		return state.label; //TODO: or state.value...
	// 	}
	//
	// 	let view = template.createEmbeddedView({$implicit: state}); //{$implicit: state}
	// 	view.detectChanges();
	// 	const container = document.createElement('div');
	// 	for (let node of view.rootNodes) {
	// 		container.appendChild(node);
	// 	}
	//
	// 	return jQuery(container);
	// }

	constructor(
		private cd: ChangeDetectorRef,
		private er: ElementRef,
		@Self() @Optional() private ngControl: NgControl,
		private translate: TranslateService,
		private config: NgSelectConfig,
		// private dragula2: DragulaService
	) {
		// this.dragula2.drop().subscribe((value) => {
		// 	if(value.name === this.randomId)
		// 		this.onValueChanged(this.orderedItems);
		// });

		if (ngControl) {
			ngControl.valueAccessor = this;
		}
	}

	setItems(i) {
		this.orderedItems = i;
		return Object.assign([], this.orderedItems);
	}

	ngOnInit(): void {


		this.registerOnChange(this._onChanged);

	}

	addTagFn(params) {
		if (params.term == '') {
			return null;
		}

		return {id: `${MetSelectComponent.NEW_TAG_PREFIX}${params}`, text: params};
	}

	ngOnChanges(changes: SimpleChanges): void {
		this._selectOptions = {
			...this.select2Options,
			// ...this.selectOptions, merge this, TODO: what about duplicate keys from select2Options?
			notFoundText: this.translate.instant('select.noResults'),
			//templateResult: (state: NgOption): JQuery | string => this.applyTemplate(state, this.resultTemplate),
			//templateSelection: (state: NgOption): JQuery | string => this.applyTemplate(state, this.selectionTemplate),
			multiple: this.multiple,
			addTags: this.allowFreeText,
			//TODO: tag handling:
			createTag: this.select2Options.createTag || ((params) => {
				if (params.term == '') {
					return null;
				}

				return {id: `${MetSelectComponent.NEW_TAG_PREFIX}${params.term}`, text: params.term};
			}),
			insertTag: this.select2Options.insertTag || ((data: any[], tag: any) => {
				//this._data.find(a => a.id == tag.text);
				data.push({id: tag.id, text: '+ ' + tag.text + ' hinzufügen'});
			}),
			tokenSeparators: this.select2Options.tokenSeparators || [',', '\n', '\r'] //TODO: check why we need this?
		};

		this.config = this._selectOptions;

		if (!this.disabled) {
			if (this.isLoading) {
				this._fc.disable();
			} else {
				this._fc.enable();
			}
		}

		if (this.disableSearch) {
			// this._select2Options.minimumResultsForSearch = Infinity; //TODO
		}

		if (changes.topGroup || changes.topGroupName) {
			this.updateTopGroup();
		}

		// if (changes.showSelectAll) {
		// 	this.updateSelectAll();
		// }
	}

	ngAfterContentInit(): void {
		this.updateData();
		this.optGroups.changes.subscribe(() => this.updateData());
		this.options.changes.subscribe(() => this.updateData());
		// this._valueChanges = this._fc.valueChanges.subscribe((v) => this.onValueChanged(v));
	}

	ngOnDestroy(): void {
		this.unsubFromOptionChanges();

		if (this._valueChanges) {
			this._valueChanges.unsubscribe();
		}
	}

	subToOptionChanges(options: Array<MetOptionComponent | MetOptionGroupComponent>): Subscription[] {
		const subscriptions = options.map(opt => {
			if (opt instanceof MetOptionComponent) {
				return [opt.change.subscribe(() => this.updateData())];
			} else if (opt instanceof MetOptionGroupComponent) {
				//subscribe to group itself
				let subs = [opt.change.subscribe(() => this.updateData())];
				//subscribe to group's children
				return subs.concat(this.subToOptionChanges(opt.options.toArray()));
			}
		});
		return [].concat(...subscriptions); //flatten subscriptions
	}

	unsubFromOptionChanges() {
		this._optionChangeSubs.forEach(sub => sub.unsubscribe());
	}

	updateData(): void {
		//save previous state
		const wasPristine = this._fc.pristine;
		const previousValue = this._fc.value;

		//convert children
		let children: (MetOptionComponent | MetOptionGroupComponent)[] = this.options.toArray();
		children = children.concat(this.optGroups.toArray());

		this._data = this.convertToSelect(children);

		this.updateTopGroup();
		// this.updateSelectAll();

		this.cd.detectChanges();

		//restore previous state
		if (wasPristine) {
			this._fc.markAsPristine();
		}
		this._fc.setValue(previousValue, {emitEvent: false});

		this.unsubFromOptionChanges();
		this._optionChangeSubs = this.subToOptionChanges(children);
	}

	updateTopGroup() {
		if (this.topGroup == null || this._data == null) {
			return;
		}

		this._topOptions = this.options.filter(opt => this.topGroup.includes(opt.id + ''));

		const group: MetOptionGroup = {
			label: this.topGroupName,
			options: this._topOptions
		};

		const otherOptions = this.options.filter(opt => !this.topGroup.includes(opt.id + ''));
		const groupGeneralOptions: MetOptionGroup = {
			label: this.translate.instant('general.generic-options'),
			options: otherOptions
		};

		// @ts-ignore
		this._data = this.convertToSelect([group].concat(otherOptions));
	}

	// updateSelectAll() {
	// 	if (this.showSelectAll) {
	// 		this.addSelectAll();
	// 	} else {
	// 		this.removeSelectAll();
	// 	}
	// }

	// addSelectAll() {
	// 	if (!this._data) {
	// 		return;
	// 	}
	//
	// 	const item: MetOption = {
	// 		id: MetSelectComponent.SELECT_ALL_ID,
	// 		text: this.translate.instant(MetSelectComponent.SELECT_ALL_TRANSLATION),
	// 		disabled: false
	// 	};
	// 	this.removeSelectAll();
	// 	this._data = this.convertToSelect([item]).concat(this._data);
	// }

	// removeSelectAll() {
	// 	if (!this._data) {
	// 		return;
	// 	}
	// 	this._data = this._data.filter(opt => opt.id != MetSelectComponent.SELECT_ALL_ID);
	// }

	convertToSelect(
		options: Mappable<(MetOptionGroup | MetOption)> & Filterable<(MetOptionGroup | MetOption)> & Someable<(MetOptionGroup | MetOption)>  | any
	): NgOption[] {
		const hasGroup = options.some((opt) => 'options' in opt)

		if(hasGroup) {
			const groupedOptions = options.filter((opt) => 'options' in opt);
			const generalOptions = options.filter((opt) => !groupedOptions.includes(opt));

			if (generalOptions?.length > 0) {
				return groupedOptions.map((opt: MetOptionGroup) => {
					return {
						text: opt.label,
						children: this.convertToSelect(opt.options),
						// disabled: true // Make group selection not possible
					} as NgOption
				}).concat(
					[{
						text: this.translate.instant('general.generic-options'),
						children: this.convertToSelect(generalOptions),
						// disabled: true // Make group selection not possible
					}]
				);
			} else {
				return groupedOptions.map((opt: MetOptionGroup) => {
					return {
						text: opt.label,
						children: this.convertToSelect(opt.options),
						// disabled: true // Make group selection not possible
					} as NgOption
				});
			}
		} else {
			return options.map(option => {
				if ('text' in option) {
					return {
						id: option.id === null ? 'SELECT2_NULL' : String(option.id),
						text: option.text,
						disabled: option.disabled,
						additional: option.additional
					};
				}
			});
		}
	}


	getItem(v) {
		return this.options.find(d => d.id == v);
		// return this._data.find(d => d.id == v);
	}

	removeSelectedItem(v) {

		//this._data.filter(d => d.id != v); //TODO

	}


	private removeIfTemporaryOption(value: string | string[] | number | number[]) { //TODO NOT NEEDED?
		if (this._data == null) {
			return;
		}
		//remove temporary options when selecting something else
		if (Array.isArray(value)) {
			// need to do a weird type cast here
			this._data = this._data.filter(
				opt => !opt?.additional?.metSelectIsTemporary // keep non-temporary options
					|| value.includes(opt.id as never)
					|| value.includes(MetSelectComponent.NEW_TAG_PREFIX + opt.id as never)
			);
			// old behaviour (was buggy when not using createNewTag):
			// for (let v of value) {
			// 	this.removeIfTemporaryOption(v);
			// }
		} else {
			this._data = this._data.filter(
				opt => !opt?.additional?.metSelectIsTemporary // keep non-temporary options
					|| value == opt.id
					|| value == MetSelectComponent.NEW_TAG_PREFIX + opt.id
			);
			// old behaviour (was buggy when not using createNewTag):
			// const oldOption = this._data.find(option => option.id == value);
			// if (oldOption?.additional?.metSelectIsTemporary == true) {
			// 	this._data = this._data.filter(option => option != oldOption);
			// }
		}
	}

	selectAll(v) {
		if (this.optGroups != null && this.optGroups.length > 0) {
			let entries = [].concat.apply([], this.optGroups.map(group => group.options.map(item => item.id + '')));
			return entries.length === v.length - 1 // already selected all
				? [] // deselect all
				: entries; // select all
		} else {
				return this.options.length === v.length - 1 // already selected all
					? [] // deselect all
					: this.options.map(item => item.id + ''); // select all
		}
	}

	selectAllByGroup(groupItem: any) {
		const selectedIds = (this.orderedItems || []).concat(groupItem.children.map(children => children.id));
		this.onValueChanged(_.uniq(selectedIds));
	}

	onValueChanged(v, selectAll: boolean = false) {
		//Condition needed for Dragula
		//TODO UNDERSTAND WHY NG-SELECT IS NOT DOING THIS
		if (Symbol.iterator in Object(v)) {
			v = v.map(v => v?.id || v);
		} else {
			v = v?.id || v
		}

		// if (this.value == v) { TODO not working with single selection and ng-select
		// 	return;
		// }

		if(selectAll)
			v = this.selectAll(v);

		// const indexOfSelectAll = v.indexOf(MetSelectComponent.SELECT_ALL_ID);
		// if (Array.isArray(v) && indexOfSelectAll >= 0) {
		// 	if (this.optGroups != null && this.optGroups.length > 0) {
		// 		let entries = [].concat.apply([], this.optGroups.map(group => group.options.map(item => item.id + '')));
		// 		v = entries.length === v.length - 1 // already selected all
		// 			? [] // deselect all
		// 			: entries; // select all
		// 	} else {
		// 		v = this.options.length === v.length - 1 // already selected all
		// 			? [] // deselect all
		// 			: this.options.map(item => item.id + ''); // select all
		// 	}
		// }
		// this.removeIfTemporaryOption(this.value);
		this.orderedItems = v;
		this.value = v;

		if (v === 'SELECT2_NULL') {
			v = null;
		}

		// if (this.value == v)
		// 	return;

		if (this._onChanged && this._fc.enabled) {
			this._onChanged(v);
		}

		if (this.allowFreeText) {
			let newTagIndex = -1;
			let newTag = null;
			if (Array.isArray(v) && v != null) {
				newTag = v.find((tag: string, i: number) => {
					if (tag.startsWith(MetSelectComponent.NEW_TAG_PREFIX)) {
						newTagIndex = i;
						return true;
					}
					return false;
				});
			} else if (v != null && v.startsWith(MetSelectComponent.NEW_TAG_PREFIX)) {
				newTag = v;
			}

			if (newTag) {
				const oldTag = newTag;
				newTag = newTag.substring(MetSelectComponent.NEW_TAG_PREFIX.length);
				if (this.createNewTag.observers.length > 0) {
					this.createNewTag.emit([newTagIndex, newTag, oldTag]);
				} else {
					this._data = [...this._data.filter(opt => opt.id != newTag), {
						id: newTag,
						text: newTag,
						additional: {
							metSelectIsTemporary: true
						}
					}];

					if (Array.isArray(v) && v != null) {
						this.onValueChanged(v.filter(e => e != oldTag).concat([newTag]));
					} else {
						this.onValueChanged(newTag);
					}
				}
			}
		}
		this.change.emit(v);
	}

	onTouched() {
		if (this._onTouched) {
			this._onTouched();
		}
	}

	registerOnChange(fn: any): void {
		this._onChanged = fn;
	}

	registerOnTouched(fn: any): void {
		this._onTouched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		if (isDisabled) {
			this._fc.disable();
			this.disabled = true;
		} else {
			this._fc.enable();
			this.disabled = false;
		}
	}

	writeValue(obj: any): void {
		if (Array.isArray(obj)) {
			obj.forEach((v, i) => obj[i] = '' + v);
		} else if ((obj || obj == 0) && (typeof obj !== "string")) {
			obj = obj + '';
		}

		if (this.value != obj) {
			this.removeIfTemporaryOption(this.value);
		}

		if (this.optGroups != null && this.optGroups.length > 0) {
			setTimeout(() => this.updateData(), 0);
		} //circumvent bug with combination of option groups and createNew


		this.value = obj; //here we set (initialize) the values that comes from outside
		//this.orderedItems = obj;
		this._fc.setValue(obj, {emitEvent: false});

		if (typeof obj === "string") {
			// this.value = [obj];
			this.orderedItems = [obj];
		} else {
			this.orderedItems = obj;
		}


		this.cd.detectChanges();
	}

	// groupByFn = (item) => item.children ? item.text : 'Options';
	// groupValueFn = (_: string, children: any[]) => ({ name: children[0].child.state, total: children.length });
}
