import {EventEmitter, Injectable} from '@angular/core';
import {User} from 'src/app/shared/models';

@Injectable({providedIn: 'root'})
export class AuthService {

	private currentUserChecked: boolean = false;
	private currentUser: User = null;
	private explodedPermissions: string[];

	public currentUserChanged = new EventEmitter<User>();

	constructor() {
	}

	/**
	 * Returns currentUser if and only if current user was checked by getCurrentUserAsync
	 * It throws an error otherwise
	 */
	getCurrentUser() {
		if(this.currentUserChecked)
			return this.currentUser;
	}

	/**
	 * This function resolves, if the currentUser is a valid and logged in user.
	 * It rejects otherwise!
	 */
	getCurrentUserAsync() : Promise<User> {
		return new Promise<User>((resolve, reject) => {
			this.setCurrentUser({
				created_at: null,
				disabled: false,
				email: null,
				formattedPermissions: [],
				id: 0,
				lang: "de",
				person: {
					created_at: null,
					email: null,
					firstname: null,
					id: 0,
					lastname: null,
					shortName: null,
					telephone: null,
					updated_at: null
				},
				roles: [],
				token: null,
			}, "");
			resolve(this.currentUser);
		});
	}

	public get fullPermissions(): string[] {
		if (!this.explodedPermissions)
			this.explodePermissions();

		return this.explodedPermissions;
	}

	login(email: string, password: string, rememberMe: boolean = true) {

	}

	logout() {

	}

	forgotPassword(email: string) {
	}

	resetPassword(token: string, email: string, password: string) {

	}

	setUserLanguage(lang: string) {
		if(!this.currentUser) return;
		this.currentUser.lang = lang;
	}

	private setCurrentUser(user, token) {
		if (token) {
			localStorage.setItem('userToken', token);
		} else {
			localStorage.removeItem('userToken');
		}

		this.currentUserChecked = true;

		if(user != null && token != null) {
			user.token = token;
		}

		this.currentUser = user;

		this.explodePermissions();

		this.currentUserChanged.emit(this.currentUser);
	}

	private explodePermissions() {
		if (!this.currentUser) {
			this.explodedPermissions = [];
			return;
		}

		const permissions = this.currentUser.formattedPermissions;
		if(!permissions) return;

		const returnArray = [];
		permissions.forEach((permission) => {
				const split = permission.split('.');
				for (let i = 1; i <= split.length; i++) {
					const permString = split.slice(0, i).join('.');
					if (returnArray.indexOf(permString) == -1)
						returnArray.push(permString);
				}
			}
		);
		this.explodedPermissions = returnArray;
	}
}
