import {Directive, ElementRef, EventEmitter, Host, HostListener, Input, Output} from '@angular/core';
import {formValueToFormData} from "../functions/formgroup";

@Directive({
	selector: '[appFileChooser]',
	exportAs: 'appFileChooser'
})
export class FileChooserDirective {
	@HostListener('change', ['$event.target.files'])
	private onFilesChosen(event: FileList) {
		this.files = event;
		this.filesChanged.emit(this.files);
	}
	files: FileList;

	@Input() maxSize: string;

	@Output() filesChanged = new EventEmitter<FileList>();

	constructor(@Host() private host: ElementRef) {
	}

	public getValue(): File | FileList {
		if (this.host.nativeElement.multiple)
			return this.getFiles();
		else
			return this.getFile();
	}

	public getFile(): File {
		if (this.files && this.files.length > 0) {
			//check for fileSize
			if(this.maxSize != null && this.files.item(0).size > parseInt(this.maxSize) * 1000)
				return null;

			return this.files.item(0);
		}
		return null;
	}

	private getFiles(): FileList {
		return this.files;
	}

	public clearSelection() {
		this.files = null;
		this.host.nativeElement.value = null;
		this.filesChanged.emit(null);
	}

	public addTo(formValue: any, name: string): FormData {
		let formData = formValueToFormData(formValue)

		return this.addToFormData(formData, name);
	}

	public addToFormData(formData: FormData, name: string): FormData {
		formData.delete(name);
		const file = this.getFile();
		if (file)
			formData.append(name, file, file.name);
		return formData;
	}

}
