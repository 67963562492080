import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import {Job} from "../../shared/models/job.obj";
import {Company} from "../../shared/models/company.obj";
import {removeSymbolsFromFileName} from "../../shared/functions/strings";
import {Paginated} from "../../shared/models/paginated.obj";
import {Subscription} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {BrandCompanyService} from "../../shared/services/brand-company.service";
import {SettingsService} from "../../shared/services/settings.service";
import {JobService} from "../../shared/services/job.service";
import {SnackBarService} from "../../shared/services/snack-bar.service";
import {ActivatedRoute} from "@angular/router";
import {LanguageService} from "../../shared/services/language.service";
import {TranslateService} from "@ngx-translate/core";
import {IsBrowserService} from "../../shared/services/isBrowser.service";
import {Language} from "../../shared/models/language.model";
import {MetFileInputComponent} from "../../shared/components/met-file-input/met-file-input.component";
import {InputsMustMatch} from "../../shared/validators/inputsMustMatch.validator";

@Component({
  selector: 'app-job-application-short',
  templateUrl: './job-application-short.component.html',
  styleUrls: ['./job-application-short.component.scss']
})
export class JobApplicationShortComponent implements OnInit, OnDestroy {
	@ViewChild("cvFile") cvFile: MetFileInputComponent;
	@ViewChild("attachmentFile") attachmentFile: MetFileInputComponent;

	private _subscription = new Subscription();
	languageServiceGetTranslations$: Subscription;

	formGroup: FormGroup = new FormGroup({
		gender: new FormControl(),
		firstname: new FormControl(),
		lastname: new FormControl(),
		cvFile: new FormControl(),
		email: new FormControl(), //honey
		attachmentFile: new FormControl(),
		comment: new FormControl(),
		defaultLanguage: new FormControl(),
		company_id: new FormControl(),
		personEmail: new FormControl(null, Validators.email),
		email_reenter: new FormControl(),
		consent: new FormControl(),
		canShare: new FormControl(false)
	}, [InputsMustMatch('personEmail', 'email_reenter')]);


	allCompanies: Company[];
	defaultLanguages: Language[];
	job: Job;
	company: Company;
	notFound: boolean = false;
	allowedFileTypes = ".pdf,.docx,.doc,image/*";
	email: string;

	applicationSent: boolean = false;

	showOverview: boolean = false;

	isSpontaneous: boolean = false;

	isLoading: boolean = false;

	private isBrowser: boolean = false;

	removeExtensionSymbols = removeSymbolsFromFileName;

	constructor(
		private http: HttpClient,
		public brandCompany: BrandCompanyService,
		private settings: SettingsService,
		private jobService: JobService,
		private snackBar: SnackBarService,
		private route: ActivatedRoute,
		private language: LanguageService,
		private translate: TranslateService,
		private isBrowserService: IsBrowserService
	) { }

	ngOnInit(): void {
		const fileTypes = this.settings.get("APPLICATION_FILE_TYPES");
		if (fileTypes) {
			this.allowedFileTypes = fileTypes;
		}

		this.notFound = false;

		this.languageServiceGetTranslations$ = this.language.getTranslations().subscribe(translations => this.defaultLanguages = translations);

		this._subscription.add(
			this.route.data.subscribe(data => {
				this.isSpontaneous = data.isSpontaneous;
				if (this.isSpontaneous) {
					this.http.get<Paginated<Company>>("/api/jobportal/companies", {
						params: {
							page: "1",
							count: "999999"
						}
					}).subscribe(c => {
						this.allCompanies = c.data;
					});
				} else {
					this.jobService.getJobByID(this.route.snapshot.params.id).subscribe(
						job => {
							this.job = job;
							this.company = job.company;
						},
						error => {
							this.notFound = error.status == 404;
						});
				}
			})
		);

		const company_id = this.brandCompany.getID();
		if (this.isSpontaneous && company_id) {
			this.formGroup.controls.company_id.setValue(company_id);
		}

		this.formGroup.controls.company_id.valueChanges.subscribe(companyId => {
			this.company = this.allCompanies.find(c => c.id == companyId);
		})

		if (this.isBrowserService.isBrowser) {
			//TODO this.makeInlineSVG();
			this.isBrowser = true;
		}
	}

	ngOnDestroy() {
		this._subscription.unsubscribe();
		if (this.languageServiceGetTranslations$) this.languageServiceGetTranslations$.unsubscribe();
	}

	/*
	makeInlineSVG() {
		document.querySelectorAll('img.svg').forEach(function (img: any) {
			let imgID = img.id;
			let imgClass = img.className;
			let imgURL = img.src;

			fetch(imgURL).then(function (response) {
				return response.text();
			}).then(function (text) {

				var parser = new DOMParser();
				var xmlDoc = parser.parseFromString(text, "text/xml");

				// Get the SVG tag, ignore the rest
				var svg = xmlDoc.getElementsByTagName('svg')[0];

				// Add replaced image's ID to the new SVG
				if (typeof imgID !== 'undefined') {
					svg.setAttribute('id', imgID);
				}
				// Add replaced image's classes to the new SVG
				if (typeof imgClass !== 'undefined') {
					svg.setAttribute('class', imgClass + ' replaced-svg');
				}

				// Remove any invalid XML tags as per http://validator.w3.org
				svg.removeAttribute('xmlns:a');

				// Check if the viewport is set, if the viewport is not set the SVG wont't scale.
				if (!svg.getAttribute('viewBox') && svg.getAttribute('height') && svg.getAttribute('width')) {
					svg.setAttribute('viewBox', '0 0 ' + svg.getAttribute('height') + ' ' + svg.getAttribute('width'))
				}

				// Replace image with new SVG
				img.parentNode.replaceChild(svg, img);

			});

		});
		// document.querySelector("img").getSVGDocument().getElementById("svgInternalID").setAttribute("fill", "red")
	}

	 */

	getLangWithShort(short: string) {
		if (short == null)
			return "";
		if (this.defaultLanguages == null)
			return "";

		return this.defaultLanguages.find(lang => lang.short == short).name;
	}

	onSubmit() {
		if (this.formGroup.invalid) {
			this.snackBar.showErrorDialog(this.translate.instant('application.form.error.errorMessage'));
			return;
		}

		this.isLoading = true;

		console.log(this.formGroup);

		const formData = this.cvFile.addTo(this.formGroup.value, "cvFile");
		this.attachmentFile.addToFormData(formData, "attachmentFile");

		if (!this.isSpontaneous) {
			formData.set("job_id", this.route.snapshot.params.id);
			formData.delete("company_id");
		} else {
			formData.delete("job_id");
		}

		this.http.post("/api/jobportal/applications", formData)
			.subscribe(() => {
				this.applicationSent = true;
				this.isLoading = false;
				}, e => {
					this.isLoading = false;
					this.snackBar.showServerError(this.translate.instant('application.form.error.errorMessage'), e, <FormGroup>this.formGroup)
				}
			);
	}

	private validateGroup() {
		this.formGroup.updateValueAndValidity();
		if (this.formGroup.invalid) {
			this.formGroup.markAllAsTouched();
			// this.wizard.stop();
		}
		return this.formGroup.valid;
	}

	confirm() {
		if(!this.validateGroup()) {
			// this.snackBar.showErrorDialog(this.translate.instant('application.form.error.errorMessage'));
		} else {
			// isLoading is important -> used to avoid multiple click
			// tslint:disable-next-line:no-unused-expression
			!this.showOverview ? (this.showOverview = true) : (this.isLoading || this.onSubmit())
		}
	}
}
