import {Component, OnInit, TemplateRef} from '@angular/core';
import {SnackBarService} from "../../services/snack-bar.service";

@Component({
	selector: 'app-snackbar-container',
	templateUrl: './snackbar-container.component.html',
	styleUrls: ['./snackbar-container.component.scss'],
	host: {
		class: 'ngb-toasts vw-100 d-flex justify-content-center'
	}
})
export class SnackbarContainerComponent implements OnInit {

	constructor(public snackbarService: SnackBarService) {
	}

	isTemplate(toast) {
		return toast.textOrTpl instanceof TemplateRef;
	}

	ngOnInit(): void {
	}

}
