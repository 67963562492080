import {TranslatedString} from "./translated-string.model";
import {Page} from "./page.obj";
import {CustomLink} from "./custom-link.obj";

export class Navigation {

	id: number;
	name: string;
	key: string;
	title: TranslatedString;
	items: NavigationItem[];

	constructor(data){

		this.id = data.id;
		this.name = data.name;
		this.key = data.key;
		this.title = data.title;
		this.items = data.items.map(item => new NavigationItem(item))
	}


}

export class NavigationItem{

	id: number;
	url:string;
	target:string = null;
	type:string;
	position:number;
	name: TranslatedString;

	constructor(data) {
			this.id = data.id;
			this.type = data.linkable_type;
			this.position = data.position;
			if(this.type == 'page'){

				this.url = data.linkable.name;
				this.name = data.linkable.title;

			}
			if(this.type == 'customLink'){

				this.url = data.linkable.url;
				this.name = data.linkable.name;
				this.target = data.linkable.target;
			}
	}

}
