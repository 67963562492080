import {HttpErrorResponse} from '@angular/common/http';
import {ErrorHandler as AngularErrorHandler} from '@angular/core';
import {environment} from '../../../environments/environment';

/**
 * Version of ErrorHandler from Sentry which is able to run in Browser (using @sentry/browser) and in Node Server (using @sentry/node)
 * Taken partially from https://github.com/getsentry/sentry-javascript/blob/master/packages/angular/src/errorhandler.ts
 * And Zone definition from https://github.com/getsentry/sentry-javascript/blob/master/packages/angular/src/zone.ts
 */


/**
 * Options used to configure the behavior of the Angular ErrorHandler.
 */
export interface ErrorHandlerOptions {
	logErrors?: boolean;
	showDialog?: boolean;
	dialogOptions?: any;

	/**
	 * Custom implementation of error extraction from the raw value captured by the Angular.
	 * @param error Value captured by Angular's ErrorHandler provider
	 * @param defaultExtractor Default implementation that can be used as the fallback in case of custom implementation
	 */
	extractor?(error: unknown, defaultExtractor: (error: unknown) => unknown): unknown;
}

/**
 * Implementation of Angular's ErrorHandler provider that can be used as a drop-in replacement for the stock one.
 */
export class SentryErrorHandler implements AngularErrorHandler {

	protected readonly _options: ErrorHandlerOptions;

	public constructor(private sentry: any, options?: ErrorHandlerOptions) {
		this._options = {
			logErrors: true,
			...options,
		};

		if (environment.production) {
			this.sentry.init({dsn: environment.sentryDSN});
		}
	}

	/**
	 * Method called for every value captured through the ErrorHandler
	 */
	public handleError(error: unknown): void {
		const extractedError = this._extractError(error) || 'Handled unknown error';

		// Capture handled exception and send it to Sentry.
		this.sentry.captureException(extractedError)
		// const eventId = runOutsideAngular(() => this.sentry.captureException(extractedError));

		// When in development mode, log the error to console for immediate feedback.
		if (this._options.logErrors) {
			// eslint-disable-next-line no-console
			console.error(extractedError);
		}

		// TODO: throw error will print JS for some reason
		// throw error;

		// May not be use on Server ~Nico
		// // Optionally show user dialog to provide details on what happened.
		// if (this._options.showDialog) {
		// 	this.sentry.showReportDialog({ ...this._options.dialogOptions, eventId });
		// }
	}

	/**
	 * Used to pull a desired value that will be used to capture an event out of the raw value captured by ErrorHandler.
	 */
	protected _extractError(error: unknown): unknown {
		// Allow custom overrides of extracting function
		if (this._options.extractor) {
			const defaultExtractor = this._defaultExtractor.bind(this);
			return this._options.extractor(error, defaultExtractor);
		}

		return this._defaultExtractor(error);
	}

	/**
	 * Default implementation of error extraction that handles default error wrapping, HTTP responses, ErrorEvent and few other known cases.
	 */
	protected _defaultExtractor(errorCandidate: unknown): unknown {
		let error = errorCandidate;

		// Try to unwrap zone.js error.
		// https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
		if (error && (error as { ngOriginalError: Error }).ngOriginalError) {
			error = (error as { ngOriginalError: Error }).ngOriginalError;
		}

		// We can handle messages and Error objects directly.
		if (typeof error === 'string' || error instanceof Error) {
			return error;
		}

		// If it's http module error, extract as much information from it as we can.
		if (error instanceof HttpErrorResponse) {
			// The `error` property of http exception can be either an `Error` object, which we can use directly...
			if (error.error instanceof Error) {
				return error.error;
			}

			// ... or an`ErrorEvent`, which can provide us with the message but no stack...
			// if (error.error instanceof ErrorEvent && error.error.message) {
			if (error.error.message) {
				return error.error.message;
			}

			// ...or the request body itself, which we can use as a message instead.
			if (typeof error.error === 'string') {
				return `Server returned code ${error.status} with body "${error.error}"`;
			}

			// If we don't have any detailed information, fallback to the request message itself.
			return error.message;
		}

		// Nothing was extracted, fallback to default error message.
		return null;
	}

	static initWith(sentry: any) {
		return () => new SentryErrorHandler(sentry);
	}
}


// That's the `global.Zone` exposed when the `zone.js` package is used.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Zone: any;

// There're 2 types of Angular applications:
// 1) zone-full (by default)
// 2) zone-less
// The developer can avoid importing the `zone.js` package and tells Angular that
// he is responsible for running the change detection by himself. This is done by
// "nooping" the zone through `CompilerOptions` when bootstrapping the root module.
// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
const isNgZoneEnabled = typeof Zone !== 'undefined' && !!Zone.current;

/**
 * The function that does the same job as `NgZone.runOutsideAngular`.
 */
export function runOutsideAngular<T>(callback: () => T): T {
	// The `Zone.root.run` basically will run the `callback` in the most parent zone.
	// Any asynchronous API used inside the `callback` won't catch Angular's zone
	// since `Zone.current` will reference `Zone.root`.
	// The Angular's zone is forked from the `Zone.root`. In this case, `zone.js` won't
	// trigger change detection, and `ApplicationRef.tick()` will not be run.
	// Caretaker note: we're using `Zone.root` except `NgZone.runOutsideAngular` since this
	// will require injecting the `NgZone` facade. That will create a breaking change for
	// projects already using the `@sentry/angular`.
	// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
	return isNgZoneEnabled ? Zone.root.run(callback) : callback();
}
