<div class="d-flex flex-column w-100">
	<div class="symbol symbol-50 symbol-lg-120 mb-4" *ngIf="previewURL">
		<img class="mw-100 w-auto" [src]="previewURL">
	</div>
	<div class="btn-group btn-group-toggle d-flex align-items-stretch flex-row w-100" data-toggle="buttons">

		<button *ngIf="style == 'frontend-upload'" type="button" class="btn btn-icon"
				style="background-color: #F3F6F9; cursor:auto; border: 1px solid #E5EAEE;">
			<span></span>
			<i class="la la-file-alt"></i>
		</button>
		<button *ngIf="style != 'frontend-upload' && getValue()" type="button" (click)="clear()" class="btn btn-icon"
				style="background-color: #F3F6F9; border: 1px solid #E5EAEE;">
			<span></span>
			<i class="la la-times-circle"></i>
		</button>

		<input (click)="touched()" (change)="change.emit($event)" style="display: none" class="custom-file-input" [id]="id" type="file"
			   [accept]="accept" [maxSize]="maxSize" appFileChooser (filesChanged)="filesChanged()" #file="appFileChooser" #inputElement>
		<label class="btn custom-file-label" [for]="id" [ngClass]="style == 'frontend-upload' ? 'hide' : ''">
			<div class="d-inline" [ngbTooltip]="_getFileNameToDisplay()">
				{{!_getFileNameToDisplay() ? ('action.selectFile' | translate) + '...' : (_getFileNameToDisplay() | threeDotsPipe:20)}}
			</div>

		</label>

		<button *ngIf="style == 'frontend-upload' && !getValue()" (click)="selectFile($event,this)" type="button"  class="btn btn-icon"
				style="background-color: #F3F6F9; border: 1px solid #E5EAEE;">
			<span></span>
			<i class="la la-upload"></i>
		</button>
		<button *ngIf="style == 'frontend-upload' && getValue()" type="button" (click)="clear()" class="btn btn-icon"
				style="background-color: #F3F6F9; border: 1px solid #E5EAEE;">
			<span></span>
			<i class="la la-times-circle"></i>
		</button>


	</div>
</div>


