import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'threeDotsPipe'
})
export class ThreeDotsPipe implements PipeTransform {

	transform(str: string, length: number): string {
		if (!str || str.length <= length) return str;
		return str.slice(0, length) + "...";
	}

}
