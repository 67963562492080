export function mapToID(object: { id: any }[]): number[] {
	return object.map(a => parseInt(a.id));
}

export function getId(object: { id: any }): number {
	return object.id;
}

export function fieldIsEqual(obj1: any, obj2: any, field: string): boolean {
	if (obj1 == null)
		return obj2 == null;
	else if (obj2 == null)
		return false;

	return obj1[field] == obj2[field];
}

/**
 * An object containing all keys of `T` with `1` as value.
 * This is basically a list of keys of `T` that is enforced by the compiler to contain exactly these keys, nothing more and nothing less.
 * @see reduce
 */
export type KeysEnum<T> = { [P in keyof Required<T>]: 1 };

/**
 * Picks the given `fields` of `obj` and returns a new object containing only those (and only if they exist in `obj`).
 * This can be used to reduce backend data exactly to the keys defined in the frontend type.
 * @param obj the object which should be limited to the given fields
 * @param fields the list of keys of the desired type. This is needed because typescript types do not exist at runtime
 */
export function reduce<T>(obj: any, fields: KeysEnum<T>): Pick<T, keyof KeysEnum<T>> {
	const result = {} as T;
	for (const f of Object.keys(fields)) {
		if (f in obj)
			result[f] = obj[f];
	}
	return result;
}

export function getRandomId(length) {
	let result = '';
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	const charactersLength = characters.length;
	let counter = 0;
	while (counter < length) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
		counter += 1;
	}
	return result;
}