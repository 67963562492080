import moment, {Moment, MomentInput} from "moment";
import {BsDatepickerConfig} from "ngx-bootstrap/datepicker";
import {DatepickerRepeatingValue} from "../models/datepicker-repeating-value.model";
import {DateValue} from "../models/date-value";
import {oneOf} from "./reflection";

export function parseDateTimeFromServer(datetime: MomentInput): Moment {
	return moment(datetime, "YYYY-MM-DD HH:mm:ss", true);
}

export function formatDateForServer(date: MomentInput): string {
	return moment(date).format("Y-MM-DD");
}

export function formatTimeForServer(time: MomentInput): string {
	const t = moment(time);
	return t.isValid() ? t.format("HH:mm") : null;
}

export function formatDateTimeForServer(date: MomentInput): string {
	return moment(date).format("Y-MM-DD HH:mm");
}

export function extractTime(time: MomentInput): { hours: number, minutes: number } {
	const t = moment(time, "[Y-MM-DD] H:mm");
	if (t.isValid())
		return {hours: t.hours(), minutes: t.minutes()};
	else
		return null;
}

export function extractTimePadded(time: MomentInput): { hours: string, minutes: string } {
	const t = moment(time, "H:mm");
	if (t.isValid())
		return {hours: t.format("HH"), minutes: t.format("mm")};
	else
		return null;
}

/** Returns the previous time that is a multiple of 15 minutes (e.g. 00:00, 00:15, 00:30, ...) */
export function previous15MinStep(time: MomentInput): Moment {
	const t = moment(time, "H:mm");
	if (t.isValid()) {
		const diff = t.minutes() % 15;
		return t.subtract(diff, 'm');
	}
	else {
		return null;
	}
}

export function extractDate(date: MomentInput): Date {
	const t = moment(date, "Y-MM-DD");
	return t.toDate();
}

export function generateRepeatingDates(value: DatepickerRepeatingValue): DateValue[] {
	if (!value || !value.repeat)
		return null;

	//calculate without the time (to not confuse isAfter) and add the time later
	let start = moment(value.startDate).hours(0).minutes(0).seconds(0).milliseconds(0);
	let end = moment(value.endDate).hours(0).minutes(0).seconds(0).milliseconds(0);

	//need the time for the diff though
	let startDateTime = start.clone().hours(value.startTime.hours).minutes(value.startTime.minutes).seconds(0);
	let endDateTime = end.clone().hours(value.endTime.hours).minutes(value.endTime.minutes).seconds(0);
	let diff = endDateTime.diff(startDateTime);

	let allDates = [];

	let endRepeat;
	let lastDate;
	switch (value.repeat.type) {
		case "daily":
			if (!value.repeat.endDate)
				return null;

			allDates.push({startDate: startDateTime, endDate: endDateTime});

			endRepeat = moment(value.repeat.endDate)
			lastDate = start.clone();
			while (true) {
				let newStart = lastDate.add(value.repeat.everyXDays, 'd');

				lastDate = addDate(allDates, newStart, value.startTime, endRepeat, diff);
				if (!lastDate)
					break;
			}
			break;
		case "weekly":
			if (!value.repeat.endDate)
				return null;

			endRepeat = moment(value.repeat.endDate);
			lastDate = start.clone();

			if (oneOf(value.repeat.weekdays)) {
				//repeat every week on the checked weekdays
				while (true) {
					let newStarts: Moment[] = [];
					let newStart = lastDate;
					//TODO: this might be incorrect in a locale that does not start the week on Monday
					if (value.repeat.weekdays.Mo)
						newStarts.push(newStart.clone().weekday(0));
					if (value.repeat.weekdays.Di)
						newStarts.push(newStart.clone().weekday(1));
					if (value.repeat.weekdays.Mi)
						newStarts.push(newStart.clone().weekday(2));
					if (value.repeat.weekdays.Do)
						newStarts.push(newStart.clone().weekday(3));
					if (value.repeat.weekdays.Fr)
						newStarts.push(newStart.clone().weekday(4));
					if (value.repeat.weekdays.Sa)
						newStarts.push(newStart.clone().weekday(5));
					if (value.repeat.weekdays.So)
						newStarts.push(newStart.clone().weekday(6));

					//filter the ones that are still within the repeat slot
					let beforeEnd = newStarts.filter(v => !v.isAfter(endRepeat));

					//add time
					const newMeetings = beforeEnd.map(v => v.hours(value.startTime.hours).minutes(value.startTime.minutes).seconds(0))
						.map(v => {
							return {startDate: v, endDate: v.clone().add(diff)};
						});

					//save dates
					allDates = allDates.concat(newMeetings);

					if (beforeEnd.length != newStarts.length) //stop if some days were not within the repeat time
						break;

					lastDate = lastDate.add(value.repeat.everyXWeeks, 'w').startOf('w');
				}
			} else {
				//normal repeat every week
				allDates.push({startDate: startDateTime, endDate: endDateTime});

				while (true) {
					let newStart = lastDate.add(value.repeat.everyXWeeks, 'w');

					lastDate = addDate(allDates, newStart, value.startTime, endRepeat, diff);
					if (!lastDate)
						break;
				}
			}


			break;
		case "monthly":
			if (!value.repeat.endDate)
				return null;

			allDates.push({startDate: startDateTime, endDate: endDateTime});

			endRepeat = moment(value.repeat.endDate)
			lastDate = start.clone();
			while (true) {
				let newStart = lastDate.add(value.repeat.everyXMonths, 'M');
				let weekDay;
				switch (value.repeat.repeatDay) {
					case "Xth":
						break;
					case "lastWeekday":
						weekDay = start.day();
						newStart = newStart.endOf('M'); //end of month
						newStart = newStart.subtract(mod(newStart.day() - weekDay, 7), 'd'); //last weekDay of month
						break;
					case "XthWeekday":
						weekDay = start.day();
						newStart = newStart.startOf('M');
						let x = Math.floor(start.date() / 7); //count zero-based
						if (weekDay < newStart.day()) //weekDay already passed in this week, so start with next week
							x++;
						newStart.day(7 * x + weekDay);
						break;
				}
				lastDate = addDate(allDates, newStart, value.startTime, endRepeat, diff);
				if (!lastDate)
					break;
			}
			break;
		case "none":
			allDates.push({startDate: startDateTime, endDate: endDateTime});
			break;
	}

	return allDates.map(d => {
		return {startDate: formatDateTimeForServer(d.startDate), endDate: formatDateTimeForServer(d.endDate)};
	});
}

function addDate(allDates: { startDate: Moment, endDate: Moment }[],
                 start: Moment,
                 startTime: { hours: number, minutes: number },
                 endRepeat: Moment,
                 diff: number): Moment {
	if (start.isAfter(endRepeat))
		return null;

	let newLastDate = start.clone().hours(0).minutes(0).seconds(0).milliseconds(0);
	start.hours(startTime.hours).minutes(startTime.minutes).seconds(0);
	allDates.push({startDate: start, endDate: start.clone().add(diff)});
	return newLastDate;
}

// default % gives different results for negative inputs
function mod(n, m) {
	return ((n % m) + m) % m;
}

export function getDatepickerConfig(): BsDatepickerConfig {
	return Object.assign(new BsDatepickerConfig(), {
		dateInputFormat: 'DD.MM.YYYY',
		containerClass: 'bs-datepicker-theme',
		customTodayClass: 'bs-datepicker-today',
	});
}
