import {
	Component,
	ContentChildren,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	QueryList,
	SimpleChanges
} from '@angular/core';
import {MetOption, MetOptionComponent} from "./met-option.component";
import {Filterable, Mappable, Someable} from "../../interfaces/functional";
import { TranslatePipe } from '@ngx-translate/core';
@Component({
	selector: 'met-option-group',
	template: '',
})
export class MetOptionGroupComponent implements MetOptionGroup, OnChanges {
	@Input() label: string;
	@Output() change = new EventEmitter();

	@ContentChildren(MetOptionComponent) options:any;// QueryList<MetOptionComponent>;

	constructor() {
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.change.emit();
	}
}

export interface MetOptionGroup {
	label: string;
	options: Mappable<MetOption> & Filterable<MetOption> & Someable<MetOption>
}
