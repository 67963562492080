<app-job-not-found *ngIf="notFound"></app-job-not-found>
<div class="content flex-column flex-column-fluid {{notFound? 'd-none': 'd-flex'}}" id="kt_content">

	<div class="d-flex flex-column-fluid">
		<div class="container p-sm-0">
			<div class="card card-custom gutter-b bg-transparent shadow-none">

				<div class="card-body p-0 bg-transparent">
					<!--begin: Wizard -->
					<div
						#wizardElem
						class="wizard wizard-3"
						data-wizard-state="step-first"
						id="kt_wizard_v3"
					>
						<div class="kt-grid__item">
							<!--begin: Form Wizard Nav -->
							<div class="row justify-content-center py-12 px-0 py-lg-0 px-lg-10">
								<div class="wizard-nav border-bottom mb-1 mb-lg-0 col-xl-12 col-xxl-12">
									<div class="wizard-steps  px-8 py-8 px-lg-0 py-lg-3">
										<a
											(click)="goTo(1)"
											class="wizard-step cursor-pointer"
											data-wizard-state="current"
											data-wizard-type="step"
										>
											<div class="wizard-label">
												<div class="wizard-title">
													<span>1</span> {{'application.nav.personalInfos' | translate}}</div>
												<div class="wizard-bar"></div>
											</div>
										</a>
										<a (click)="goTo(2)" class="wizard-step cursor-pointer" data-wizard-type="step">
											<div class="wizard-label">
												<div class="wizard-title">
													<span>2</span> {{'application.nav.upload' | translate}}</div>
												<div class="wizard-bar"></div>
											</div>
										</a>
										<a (click)="goTo(3)" class="wizard-step cursor-pointer" data-wizard-type="step">
											<div class="wizard-label">
												<div class="wizard-title">
													<span>3</span> {{'application.nav.overview' | translate}}</div>
												<div class="wizard-bar"></div>
											</div>
										</a>
										<a (click)="goTo(4)" class="wizard-step cursor-pointer" data-wizard-type="step">
											<div class="wizard-label">
												<div class="wizard-title">
													<span>4</span> {{'application.nav.success' | translate}}</div>
												<div class="wizard-bar"></div>
											</div>
										</a>
									</div>
								</div>
							</div>
							<!--end: Form Wizard Nav -->
						</div>
						<div class="row justify-content-center py-5 px-0 py-lg-5 px-lg-10">
							<div class="col-xl-12 col-xxl-12">
								<!--begin: Form Wizard Form-->
								<form [formGroup]="wizardForm" class="form" id="kt_form">

									<!--begin: Form Wizard Step 1-->
									<div class="pb-5" data-wizard-state="current" data-wizard-type="step-content">
										<div [formGroup]="wizardForm.controls.basicGroup">
											<input autocomplete="off" formControlName="email" style="display:none !important"
											       tabindex="-1">

											<kt-portlet *ngIf="isSpontaneous && !this.brandCompany.isSet()">
												<kt-portlet-body>
													<met-form-field class="col-lg-3">
														<met-label>{{'application.form.company' | translate}}</met-label>
														<met-select [disableSearch]="true" [placeholder]="'application.form.select_company' | translate"
														            formControlName="company_id"
														            required>

															<met-option *ngFor="let c of allCompanies" [id]="c.id"
															            [text]="c.name"></met-option>
														</met-select>
														<met-hint><span
															[innerHtml]="'application.form.info.company' | translate"></span>
														</met-hint>
														<met-error><span
															[innerHTML]="'application.form.error.company' | translate"></span>
														</met-error>
													</met-form-field>
												</kt-portlet-body>
											</kt-portlet>

											<div class="card card-custom mb-5">
												<div class="card-body">

													<div class="form-group row">

														<met-form-field class="col-lg-3">
															<met-label>{{'application.form.salutation' | translate}}</met-label>
															<met-select [disableSearch]="true" [placeholder]="'application.form.selectSalutation' | translate"
															            formControlName="gender"
															            required>

																<met-option id="m"
																            text="{{'salutation.male' | translate}}"></met-option>
																<met-option id="f"
																            text="{{'salutation.female' | translate}}"></met-option>
																<met-option id="d"
																			text="{{'salutation.diverse' | translate}}"></met-option>
															</met-select>
															<met-error><span
																[innerHTML]="'application.form.error.salutation' | translate"></span>
															</met-error>
														</met-form-field>

													</div>
													<div class="form-group row">
														<met-form-field class="col-lg-6">
															<met-label>{{'application.form.firstname' | translate}}</met-label>
															<input formControlName="firstname" metInput required>

															<met-error><span
																[innerHTML]="'application.form.error.firstname' | translate"></span>
															</met-error>
														</met-form-field>

														<met-form-field class="col-lg-6">
															<met-label>{{'application.form.lastname' | translate}}</met-label>
															<input formControlName="lastname" metInput required>

															<met-error><span
																[innerHTML]="'application.form.error.lastname' | translate"></span>
															</met-error>
														</met-form-field>


													</div>
													<div class="form-group row">


														<met-form-field class="col-lg-6">
															<met-label>{{'application.form.preferred_communication_language' | translate}}</met-label>
															<met-select [disableSearch]="true"
															            [placeholder]="'application.form.selectLanguage' | translate"
															            formControlName="defaultLanguage"
															            required>
																<met-option
																	*ngFor="let defaultLanguage of defaultLanguages"
																	[id]="defaultLanguage.short"
																	[text]="defaultLanguage.localeName | chooseTranslation"></met-option>
															</met-select>
															<!--<met-hint>Wählen Sie eine <b>Sprache</b> aus</met-hint>-->
															<met-error><span
																[innerHTML]="'application.form.error.preferred_communication_language' | translate"></span>
															</met-error>
														</met-form-field>


													</div>

												</div>
											</div>

											<div class="card card-custom mb-5">
												<div class="card-header">
													<div class="card-title">
														<h3 class="card-label">{{'application.form.address' | translate}}</h3>
													</div>
												</div>
												<div class="card-body">

													<div class="form-group row">

														<met-form-field class="col-lg-4">
															<met-label>{{'application.form.street' | translate}}</met-label>
															<input formControlName="street" metInput required>

															<met-error><span
																[innerHTML]="'application.form.error.street' | translate"></span>
															</met-error>
														</met-form-field>

														<met-form-field class="col-lg-2">
															<met-label>{{'application.form.house_number' | translate}}</met-label>
															<input formControlName="houseNumber" metInput required>

															<met-error><span
																[innerHTML]="'application.form.error.house_number' | translate"></span>
															</met-error>
														</met-form-field>


														<met-form-field class="col-lg-6">
															<met-label>{{'application.form.city' | translate}}</met-label>
															<input formControlName="city" metInput required>

															<met-error><span
																[innerHTML]="'application.form.error.city' | translate"></span>
															</met-error>
														</met-form-field>
													</div>
													<div class="form-group row">

														<met-form-field class="col-lg-6">
															<met-label>{{'application.form.zipCode' | translate}}</met-label>
															<input formControlName="postalCode" metInput required>

															<met-error><span
																[innerHTML]="'application.form.error.zipCode' | translate"></span>
															</met-error>
														</met-form-field>


														<met-form-field class="col-lg-6">
															<met-label>{{'application.form.country' | translate}}</met-label>
															<met-select (change)="setFormValue($event,'basicGroup.country_id')"
															            [placeholder]="'application.form.country' | translate"
															            formControlName="country_id"
															            required>
																<met-option
																	*ngFor="let c of allCountries"
																	[id]="c.id"
																	[text]="c.countryName | chooseTranslation"></met-option>
															</met-select>

															<met-error><span
																[innerHTML]="'application.form.error.country' | translate"></span>
															</met-error>
														</met-form-field>

													</div>


												</div>
											</div>

											<div class="card card-custom mb-5">
												<div class="card-header">
													<div class="card-title">
														<h3 class="card-label">{{'application.form.contact' | translate}}</h3>
													</div>
												</div>
												<div class="card-body">

													<div class="form-group row">
														<met-form-field class="col-lg-6">
															<met-label>Email</met-label>
															<input formControlName="personEmail" metInput required
															       type="email">
															<!--<met-error [appServerError]="formGroup.controls.email">Geben Sie eine valide <b>Email-Adresse</b> ein
															</met-error>-->
														</met-form-field>

														<met-form-field class="col-lg-6">
															<met-label>{{'application.form.repeatEmail' | translate}}</met-label>
															<input formControlName="email_reenter" metInput required
															       type="email">
															<!--<met-error [appServerError]="formGroup.controls.email">Geben Sie eine valide <b>Email-Adresse</b> ein
															</met-error>-->
														</met-form-field>
													</div>
													<div class="form-group row">

														<met-form-field class="col-lg-6">
															<met-label>{{'application.form.phone' | translate}}</met-label>
															<input formControlName="telephone" metInput>
														</met-form-field>

													</div>

												</div>
											</div>

											<div class="card card-custom mb-5">

												<div class="card-header">
													<div class="card-title">
														<h3 class="card-label">{{'application.form.optional_comment' | translate}}</h3>
													</div>
												</div>
												<div class="card-body">

													<div class="form-group row">

														<met-form-field class="col-12">
															<met-label>{{'application.form.comment' | translate}}</met-label>
															<textarea [rows]="10" class="form-control"
															          formControlName="comment"></textarea>
														</met-form-field>


													</div>


												</div>
											</div>


										</div>

									</div>
									<!--begin: Form Wizard Step 2-->
									<div class="pb-5" data-wizard-type="step-content">

										<div class="card card-custom mb-5">
											<div class="card-header">
												<div class="card-title">
													<h3 class="card-label mr-2">{{'application.form.upload_your_application' | translate}}
														<i [ngbTooltip]="fileTypeInfo" class="la la-info-circle"></i>
													</h3>
													<ng-template #fileTypeInfo>
														<span>{{'application.form.allowedFileTypes' | translate}}:
															<ul style="list-style-type: none; text-align: start">
																<li *ngFor="let extention of removeExtensionSymbols(allowedFileTypes).split(',')">
																	{{extention}}
																</li>
															</ul></span>
													</ng-template>
												</div>
											</div>
											<div class="card-body" formGroupName="uploadGroup">
												<div class="form-group row">

													<met-form-field class="col-lg-12">
														<met-label>{{'application.form.letterOfMotivation' | translate}}</met-label>
														<met-file-input #motivationFile [accept]="allowedFileTypes"
														                [style]="'frontend-upload'"
														                formControlName="motivationFile"
														                maxSize="5000"></met-file-input>
														<met-hint>
															<span
																[innerHTML]="'application.form.info.selectFile' | translate:{number:5}"></span>
															|
															<span>{{'application.form.allowedFileTypes' | translate}}
																: {{removeExtensionSymbols(allowedFileTypes)}}</span>
														</met-hint>
														<met-error><span
															[innerHTML]="'application.form.error.selectFile' | translate"></span>
														</met-error>
													</met-form-field>

												</div>

												<div class="form-group row">

													<met-form-field class="col-lg-12">
														<met-label>{{'application.form.cv' | translate}}</met-label>
														<met-file-input #cvFile [accept]="allowedFileTypes"
														                [style]="'frontend-upload'" formControlName="cvFile"
														                maxSize="8000"
														                required></met-file-input>
														<met-hint>
															<span
																[innerHTML]="'application.form.info.selectFile' | translate:{number:8}"></span>
															|
															<span>{{'application.form.allowedFileTypes' | translate}}
																: {{removeExtensionSymbols(allowedFileTypes)}}</span>
														</met-hint>
														<met-error><span
															[innerHTML]="'application.form.error.selectFile' | translate"></span>
														</met-error>
													</met-form-field>

												</div>

												<div class="form-group row">

													<met-form-field class="col-lg-12">
														<met-label>{{'application.form.attachment' | translate}}</met-label>
														<met-file-input #attachmentFile [accept]="allowedFileTypes"
														                [style]="'frontend-upload'"
														                formControlName="attachmentFile"
														                maxSize="8000"></met-file-input>
														<met-hint>
															<span
																[innerHTML]="'application.form.info.selectFile' | translate:{number:8}"></span>
															|
															<span>{{'application.form.allowedFileTypes' | translate}}
																: {{removeExtensionSymbols(allowedFileTypes)}}</span>
														</met-hint>
														<met-error><span
															[innerHTML]="'application.form.error.selectFile' | translate"></span>
														</met-error>
													</met-form-field>


												</div>
											</div>
										</div>
									</div>
									<!--begin: Form Wizard Step 3-->
									<div class="pb-5" data-wizard-type="step-content">
										<h4 class="mb-10 font-weight-bold text-dark">
											{{'application.nav.overview' | translate}}
										</h4>

										<div class="card card-custom mb-5">
											<div class="card-header">
												<div class="card-title">
													<h3 *ngIf="!isSpontaneous"
													    class="card-label">{{'application.form.job' | translate}}</h3>
													<h3 *ngIf="isSpontaneous"
													    class="card-label">{{'application.form.company' | translate}}</h3>
												</div>
											</div>
											<div class="card-body">
												<h4 *ngIf="!isSpontaneous">{{job?.name | chooseTranslation}}</h4>
												<h4 *ngIf="isSpontaneous">{{company?.name}}</h4>
											</div>
										</div>


										<div class="card card-custom mb-5">
											<div class="card-body">

												<div class="form-group row">

													<met-form-field class="col-lg-2">
														<met-label>{{'application.form.salutation' | translate}}</met-label>
														<input [disabled]="true"
														       [value]="wizardForm.value.basicGroup.gender | salutation | translate"
														       metInput>
													</met-form-field>
													<met-form-field class="col-sm-4 col-lg-4 col-xl-4">
														<met-label>{{'application.form.firstname' | translate}}</met-label>
														<input [disabled]="true"
														       [value]="wizardForm.value.basicGroup.firstname"
														       metInput>
													</met-form-field>

												</div>

												<div class="form-group row">

													<met-form-field class="col-lg-6">
														<met-label>{{'application.form.lastname' | translate}}</met-label>
														<input [disabled]="true"
														       [value]="wizardForm.value.basicGroup.lastname"
														       metInput>
													</met-form-field>

												</div>


											</div>
										</div>


										<div class="card card-custom mb-5">
											<div class="card-header">
												<div class="card-title">
													<h3 class="card-label">{{'application.form.address' | translate}}</h3>
												</div>
											</div>
											<div class="card-body">

												<div class="form-group row">

													<!--
												<met-form-field class="col-lg-4">
													<met-label>{{'application.form.street' | translate}}</met-label>
													<input metInput
														   [value]="wizardForm.value.basicGroup.street"
														   [disabled]="true">

													<met-error><span [innerHTML]="'application.form.error.street' | translate"></span></met-error>
												</met-form-field>


												<met-form-field class="col-lg-2">
													<met-label>{{'application.form.house_number' | translate}}</met-label>
													<input metInput
														   [value]="wizardForm.value.basicGroup.houseNumber"
														   [disabled]="true">

													<met-error><span [innerHTML]="'application.form.error.house_number' | translate"></span></met-error>
												</met-form-field>
												-->
													<met-form-field class="col-lg-6">
														<met-label>{{'application.form.street_and_house_number' | translate}}</met-label>
														<input [disabled]="true"
														       [value]="'application.form.streetHouseNumberValue' | translate:{street:wizardForm.value.basicGroup?.street, number:wizardForm.value.basicGroup?.houseNumber}"
														       metInput>

													</met-form-field>


													<met-form-field class="col-lg-6">

														<met-label>{{'application.form.city' | translate}}</met-label>
														<input [disabled]="true"
														       [value]="wizardForm.value.basicGroup.city"
														       metInput>
													</met-form-field>

												</div>
												<div class="form-group row">

													<met-form-field class="col-lg-6">
														<met-label>{{'application.form.zipCode' | translate}}</met-label>
														<input [disabled]="true"
														       [value]="wizardForm.value.basicGroup.postalCode"
														       metInput>
													</met-form-field>

													<met-form-field class="col-lg-6">
														<met-label>{{'application.form.country' | translate}}</met-label>
														<input [disabled]="true"
														       [value]="wizardForm.value.basicGroup.country_id | countryName:allCountries | chooseTranslation"
														       metInput>
													</met-form-field>
												</div>


											</div>
										</div>

										<div class="card card-custom mb-5">
											<div class="card-header">
												<div class="card-title">
													<h3 class="card-label">{{'application.form.contact' | translate}}</h3>
												</div>
											</div>
											<div class="card-body">

												<div class="form-group row">
													<met-form-field class="col-lg-6">
														<met-label>Email</met-label>
														<input [disabled]="true"
														       [value]="wizardForm.value.basicGroup.personEmail"
														       metInput>
													</met-form-field>

												</div>
												<div class="form-group row">

													<met-form-field class="col-lg-6">
														<met-label>{{'application.form.phone' | translate}}</met-label>
														<input [disabled]="true"
														       [value]="wizardForm.value.basicGroup.telephone"
														       metInput>
													</met-form-field>

												</div>

											</div>
										</div>

										<div class="card card-custom mb-5">
											<div class="card-header">
												<div class="card-title">
													<h3 class="card-label">{{'application.form.languages' | translate }}</h3>
												</div>
											</div>
											<div class="card-body">
												<met-form-field class="col-lg-6">
													<met-label>{{'application.form.preferred_communication_language' | translate }}</met-label>
													<input [disabled]="true"
													       [value]="getLangWithShort(wizardForm.value.basicGroup.defaultLanguage)"
													       metInput>
												</met-form-field>
											</div>
										</div>

										<div class="card card-custom mb-5">
											<div class="card-header">
												<div class="card-title">
													<h3 class="card-label">{{'application.form.comments' | translate}}</h3>
												</div>
											</div>
											<div class="card-body">

												<div class="form-group row">


												</div>
												<met-form-field class="col-12">
													<met-label>{{'application.form.comment' | translate}}</met-label>
													<textarea [disabled]="true" [rows]="10"
													          [value]="wizardForm.value.basicGroup.comment"
													          class="form-control"></textarea>
												</met-form-field>


											</div>
										</div>

										<div class="card card-custom mb-5">
											<div class="card-header">
												<div class="card-title">
													<h3 class="card-label">{{'application.form.documents' | translate}}</h3>
												</div>
											</div>
											<div class="card-body">

												<div class="form-group row">
													<div class="mr-4 text-center">
														<div
															class="border border-light-dark border-1 rounded-sm position-relative">
															<i class="la la-5x la-file-alt m-10"></i>
															<span *ngIf="motivationFile.getValue() != null"
															      class="label label-brand position-absolute"
															      style="right: 10px; top: 10px;">
																<i class="la la-check font-size-sm text-inverse-brand"></i>
															</span>
														</div>
														<span
															class="font-weight-bold">{{'application.form.letterOfMotivation' | translate}}</span>
													</div>
													<div class="mr-4 text-center">
														<div
															class="border border-light-dark border-1 rounded-sm position-relative">
															<i class="la la-5x la-file-alt m-10"></i>
															<span *ngIf="cvFile.getValue() != null"
															      class="label label-brand position-absolute"
															      style="right: 10px; top: 10px;">
																<i class="la la-check font-size-sm text-inverse-brand"></i>
															</span>
														</div>
														<span
															class="font-weight-bold">{{'application.form.cv' | translate}}</span>
													</div>
													<div class="text-center">
														<div
															class="border border-light-dark border-1 rounded-sm position-relative">
															<i class="la la-5x la-file-alt m-10"></i>
															<span *ngIf="attachmentFile.getValue() != null"
															      class="label label-brand position-absolute"
															      style="right: 10px; top: 10px;">
																<i class="la la-check font-size-sm text-inverse-brand"></i>
															</span>
														</div>
														<span
															class="font-weight-bold">{{'application.form.attachment' | translate}}</span>
													</div>
												</div>

											</div>
										</div>

										<div class="card card-custom mb-5" formGroupName="summaryGroup" style="background:#F5F5F5; color:black;">
											<div class="card-body">
												<met-form-field class="col-12">
													<div class="checkbox-inline mb-5">
														<label
															class="checkbox checkbox-lg checkbox-outline checkbox-brand">
															<input formControlName="consent" metInput required
															       type="checkbox"/>
															<span></span>
															<p class="custom-checkbox-label">
																{{'application.form.data_policy_consent' | translate:{company: company?.name} }}
															</p>
														</label>
													</div>
													<met-error>{{'application.form.error.data_policy_consent' | translate}}</met-error>
												</met-form-field>
											</div>
										</div>

										<div class="card card-custom mb-5" style="background:#F5F5F5; color:black;" formGroupName="summaryGroup">
											<div class="card-body">
												<met-form-field class="col-12">
													<div class="checkbox-inline mb-5">
														<label
															class="checkbox checkbox-lg checkbox-outline checkbox-brand">
															<input formControlName="canShare" metInput type="checkbox"/>
															<!--formControlName="consent"-->
															<span></span>
															<p class="custom-checkbox-label">
																{{'application.form.job_share_consent' | translate}}
															</p>
														</label>
													</div>
												</met-form-field>
											</div>
										</div>


									</div>
									<!--end: Form Wizard Step 3-->

									<!--begin: Form Wizard Step 4-->
									<div class="pb-5" data-wizard-type="step-content">
										<div class="card card-custom mb-5">
											<div class="card-body p-20">
												<div class="row">
													<div class="col-lg-6 pt-0 pt-md-30">
														<h4 class="mb-8">{{'application.form.successTitle'| translate}}</h4>

														<div class="font-weight-bold text-muted">
															{{'application.form.successInfo'| translate:{email: wizardForm.value.basicGroup.personEmail} }}
														</div>
													</div>
													<div class="col-lg-6">
														<div class="svg-wrapper">
															<div class="svg-element">
																<img class="svg"
																     height="500"
																     src="/assets/media/jobportal/illustrations/upload-complete.svg" width="500">
															</div>
														</div>
													</div>
												</div>

												<a class="btn btn-brand mt-0"
												   routerLink="/job">{{'application.form.backToJoblistBtn' | translate}}</a>
											</div>
										</div>

									</div>
									<!--end: Form Wizard Step 4-->

									<!--begin: Form Actions -->
									<div class="d-flex justify-content-between border-top">
										<div class="mr-2">
											<div *ngIf="!wizard?.isLastStep()"
											     class="btn btn-dark-75 font-weight-bold text-uppercase px-9 py-4"
											     data-wizard-type="action-prev"
											>
												{{'prevBtn' | translate}}
											</div>
										</div>
										<div>
											<button
												[disabled]="isLoading"
												(click)="wizard.currentStep == wizard.totalSteps - 1 ? (isLoading || onSubmit()) : null"
												class="btn btn-brand font-weight-bold text-uppercase px-9 py-4"
												data-wizard-type="action-next"
											>
												<ng-container
													*ngIf="wizard?.currentStep == wizard?.totalSteps - 1; else continueBtn">
													{{'application.form.sendApplicationBtn' | translate}}
												</ng-container>
												<ng-template #continueBtn>
													{{'nextBtn' | translate}}
												</ng-template>
											</button>
										</div>
									</div>
									<!--end: Form Actions -->
								</form>
								<!--end: Form Wizard Form-->
							</div>
						</div>
					</div>
					<!--end: Wizard -->
				</div>
			</div>

		</div>
	</div>
</div>

