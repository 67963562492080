import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import {InputsMustMatch} from "../../shared/validators/inputsMustMatch.validator";
import {MetFileInputComponent} from "../../shared/components/met-file-input/met-file-input.component";
import {SnackBarService} from "../../shared/services/snack-bar.service";
import {ActivatedRoute} from "@angular/router";
import {JobService} from "../../shared/services/job.service";
import {Job} from "../../shared/models/job.obj";
import {Subscription} from "rxjs";
import {Company} from "../../shared/models/company.obj";
import {Paginated} from "../../shared/models/paginated.obj";
import {BrandCompanyService} from "../../shared/services/brand-company.service";
import {TranslateService} from "@ngx-translate/core";
import {LanguageService} from "../../shared/services/language.service";
import {Country} from "../../shared/models/country.model";
import {Language} from "../../shared/models/language.model";
import _ from "lodash";
import {SettingsService} from "../../shared/services/settings.service";
import {removeSymbolsFromFileName} from "../../shared/functions/strings";
import {IsBrowserService} from "../../shared/services/isBrowser.service";
import {HttpClient} from "@angular/common/http";


@Component({
	selector: 'app-job-application',
	templateUrl: './job-application.component.html',
	styleUrls: ['./job-application.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class JobApplicationComponent implements OnInit, AfterViewInit, OnDestroy {

	@ViewChild("cvFile") cvfile: MetFileInputComponent;
	@ViewChild("attachmentFile") attachmentFile: MetFileInputComponent;
	@ViewChild("motivationFile") motivationFile: MetFileInputComponent;

	job: Job;
	company: Company;
	notFound: boolean = false;
	allowedFileTypes = ".pdf,.docx,.doc,image/*";
	email: string;
	isLoading: boolean = false;

	isSpontaneous: boolean = false;
	removeExtensionSymbols = removeSymbolsFromFileName;


	//TODO: API request for defaultLanguages
	defaultLanguages: Language[];

	@ViewChild('wizardElem', {static: true}) el: ElementRef;
	wizardForm: FormGroup = new FormGroup({

		basicGroup: new FormGroup({
				company_id: new FormControl(), //only required for spontaneous application
				gender: new FormControl(),
				defaultLanguage: new FormControl(),
				firstname: new FormControl(),
				lastname: new FormControl(),

				street: new FormControl(),
				houseNumber: new FormControl(),
				city: new FormControl(),
				postalCode: new FormControl(),
				country_id: new FormControl(),

				personEmail: new FormControl(null, Validators.email),
				email_reenter: new FormControl(),
				telephone: new FormControl(),
				comment: new FormControl(),
				email: new FormControl(),
			},
			[InputsMustMatch('personEmail', 'email_reenter')]
		),
		uploadGroup: new FormGroup({
			motivationFile: new FormControl(),
			attachmentFile: new FormControl(),
			cvFile: new FormControl()
		}),
		summaryGroup: new FormGroup({
			consent: new FormControl(false),
			canShare: new FormControl(false)
		})
	});
	wizard: any;
	allCompanies: Company[];
	allCountries: Country[] = [
		{
			"id": 1,
			"name": "Afghanistan",
			"countryName": {
				"de": "Afghanistan",
				"en": "Afghanistan",
				"fr": "Afghanistan"
			},
			"short": "af",
			"locale": "af_AF",
			"localeName": {
				"de": "Afghanistan",
				"en": "Afghanistan",
				"fr": "Afghanistan"
			},
			"currency": "AFN"
		},
	];
	languageServiceGetTranslations$: Subscription;
	private _subscription = new Subscription();
	private isBrowser: boolean = false;

	constructor(private http: HttpClient,
							public brandCompany: BrandCompanyService,
							private settings: SettingsService,
							private jobService: JobService,
							private snackBar: SnackBarService,
							private route: ActivatedRoute,
							private language: LanguageService,
							private translate: TranslateService,
							private isBrowserService: IsBrowserService) {
	}

	setFormValue(value, field: string) {
		//'basicGroup.country_id'
		this.wizardForm.get(field).setValue(value);

		//this.wizardForm.controls['country_id'].setValue(value);
	}

	ngOnInit() {

		const fileTypes = this.settings.get("APPLICATION_FILE_TYPES");
		if (fileTypes) {
			this.allowedFileTypes = fileTypes;
		}

		this.notFound = false;

		this.http.get<Country[]>("/api/jobportal/country").subscribe(countries => {
			const topShorts = ["lu", "fr", "de", "be"];
			const top = [];
			const rest = [];
			for (let c of countries) {
				if (topShorts.indexOf(c.short) >= 0)
					top.push(c);
				else
					rest.push(c);
			}
			top.sort((a, b) => this.language.chooseTranslation(a.countryName).localeCompare(this.language.chooseTranslation(b.countryName)));
			rest.sort((a, b) => this.language.chooseTranslation(a.countryName).localeCompare(this.language.chooseTranslation(b.countryName)));
			this.allCountries = top.concat(rest);
		});

		this.languageServiceGetTranslations$ = this.language.getTranslations().subscribe(translations => this.defaultLanguages = translations);

		this._subscription.add(
			this.route.data.subscribe(data => {
				this.isSpontaneous = data.isSpontaneous;
				if (this.isSpontaneous) {
					this.http.get<Paginated<Company>>("/api/jobportal/companies", {
						params: {
							page: "1",
							count: "999999"
						}
					}).subscribe(c => this.allCompanies = c.data);
				} else {
					this.jobService.getJobByID(this.route.snapshot.params.id).subscribe(
						job => {
							this.job = job;
							this.company = job.company;

						},
						error => {
							this.notFound = error.status == 404;
						});
				}
			})
		);

		const company_id = this.brandCompany.getID();
		if (this.isSpontaneous && company_id) {
			this.wizardForm.patchValue({
				basicGroup: {
					company_id
				}
			});
		}

		if (this.isBrowserService.isBrowser) {
			//TODO this.makeInlineSVG();
			this.isBrowser = true;
		}
	}

	initWizard() {

		// Initialize form wizard
		this.wizard = new KTWizard(this.el.nativeElement, {
			startStep: 1
		});
		// this.cd.detectChanges();

		// Validation before going to next page
		this.wizard.on('beforeNext', (wizardObj) => {
			if (this.validatePage(wizardObj.currentStep) && this.isSpontaneous)
				this.company = this.allCompanies.find(c => c.id + "" == this.wizardForm.value.basicGroup.company_id);
		});

		// Change event
		if (this.isBrowser) {
			this.wizard.on('change', () => {
				setTimeout(() => {
					KTUtil.scrollTop();
				}, 500);
			});
		}
	}

	ngAfterViewInit(): void {
		if (this.isBrowser)
			this.initWizard();
	}

	onSubmit() {
		this.validateGroup(this.wizardForm);

		if (this.wizardForm.invalid || this.wizardForm.value.basicGroup.email)
			return;

		this.isLoading = true;

		this.wizard.stop();
		const formData = this.cvfile.addTo({
			...this.wizardForm.value.basicGroup,
			...this.wizardForm.value.summaryGroup
		}, "cvFile");
		this.motivationFile.addToFormData(formData, "motivationFile");
		this.attachmentFile.addToFormData(formData, "attachmentFile");
		if (!this.isSpontaneous) {
			formData.set("job_id", this.route.snapshot.params.id);
			formData.delete("company_id");
		} else {
			formData.delete("job_id");
		}
		this.http.post('/api/jobportal/application', formData)
			.subscribe({
				next: () => {
					// this.snackBar.showSuccessDialog(String(this.translate.instant('application.form.successMessage')));
					this.isLoading = false;
					this.wizard.start();
					this.wizard.goTo(4);
				},
				error: e => {
					this.isLoading = false;
					if (e?.error?.message == "DAILY_APPLICATION_LIMIT_EXCEEDED") {
						this.snackBar.showErrorDialog(this.translate.instant('application.form.error.dailyLimitExceeded'));
					} else {
						this.snackBar.showServerError(this.translate.instant('application.form.error.errorMessage'), e)
					}
				}
			});
	}

	ngOnDestroy() {
		this.wizard = undefined;
		this._subscription.unsubscribe();
		if (this.languageServiceGetTranslations$) this.languageServiceGetTranslations$.unsubscribe();
	}

	getLangWithShort(short: string) {
		if (short == null)
			return "";
		if (this.defaultLanguages == null)
			return "";

		return this.defaultLanguages.find(lang => lang.short == short).name;
	}

	/*
	 * Replace all SVG images with inline SVG
	 */


	/*
	makeInlineSVG() {
		document.querySelectorAll('img.svg').forEach(function (img: any) {
			let imgID = img.id;
			let imgClass = img.className;
			let imgURL = img.src;

			fetch(imgURL).then(function (response) {
				return response.text();
			}).then(function (text) {

				var parser = new DOMParser();
				var xmlDoc = parser.parseFromString(text, "text/xml");

				// Get the SVG tag, ignore the rest
				var svg = xmlDoc.getElementsByTagName('svg')[0];

				// Add replaced image's ID to the new SVG
				if (typeof imgID !== 'undefined') {
					svg.setAttribute('id', imgID);
				}
				// Add replaced image's classes to the new SVG
				if (typeof imgClass !== 'undefined') {
					svg.setAttribute('class', imgClass + ' replaced-svg');
				}

				// Remove any invalid XML tags as per http://validator.w3.org
				svg.removeAttribute('xmlns:a');

				// Check if the viewport is set, if the viewport is not set the SVG wont't scale.
				if (!svg.getAttribute('viewBox') && svg.getAttribute('height') && svg.getAttribute('width')) {
					svg.setAttribute('viewBox', '0 0 ' + svg.getAttribute('height') + ' ' + svg.getAttribute('width'))
				}

				// Replace image with new SVG
				img.parentNode.replaceChild(svg, img);

			});

		});
		// document.querySelector("img").getSVGDocument().getElementById("svgInternalID").setAttribute("fill", "red")


	}

	 */

	validatePage(page: number) {
		switch (page) {
			case 1:
				return this.validateGroup(this.wizardForm.controls.basicGroup);
			case 2:
				return this.validateGroup(this.wizardForm.controls.uploadGroup);
			case 3:
				return this.validateGroup(this.wizardForm.controls.summaryGroup);
		}
	}

	goTo(page: number) {
		if (!_.every(_.range(1, page), prev => this.validatePage(prev)))
			return;

		this.wizard.start();
		this.wizard.goTo(page, true);
	}

	private validateGroup(group: AbstractControl) {
		group.updateValueAndValidity();
		if (group.invalid) {
			group.markAllAsTouched();
			this.wizard.stop();
		}
		return group.valid;
	}
}
