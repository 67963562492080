import {Injectable} from '@angular/core';
import {Company, CompanyFileUsage} from "../models/company.obj";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {FileObj} from "../models/file-obj.model";
import {Page} from "../models/page.obj";
import {Navigation, NavigationItem} from "../models/navigation.obj";
import {map} from "rxjs/operators";

@Injectable({
	providedIn: 'root'
})
export class NavigationService {

	constructor(private http: HttpClient) {
	}

	getNavigationByKey(key: String): Observable<Navigation> {
		return this.http.get<Navigation>("/api/navigation/public/" + key).pipe(
			map(data => new Navigation(data))
		);
	}

	getAllNavigations(): Observable<Navigation[]>{
		return this.http.get<Navigation[]>("/api/navigations/public");
	}

}
