import {Inject, Injectable, PLATFORM_ID} from "@angular/core";
import {isPlatformBrowser} from "@angular/common";

@Injectable({providedIn: 'root'})
export class IsBrowserService {

	public isBrowser: boolean;

	constructor(@Inject(PLATFORM_ID) private platformId: any) {
		this.isBrowser = isPlatformBrowser(platformId);
	}
}
