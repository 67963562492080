import {EventEmitter, Injectable, OnDestroy} from '@angular/core';
import {AuthService} from "./auth.service";
import {TranslatedString} from "../models/translated-string.model";
import {Observable, of, Subscription} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {tap} from "rxjs/operators";
import moment from "moment";
import {defineLocale} from "ngx-bootstrap/chronos";
import {deLocale, enGbLocale as enLocale, frLocale} from 'ngx-bootstrap/locale';
import {TranslateService} from "@ngx-translate/core";
import {BsLocaleService} from "ngx-bootstrap/datepicker";
import {Language} from "../models/language.model";
import {LocalStorage} from "../models/local-storage.obj";
import {IsBrowserService} from "./isBrowser.service";

@Injectable({
	providedIn: 'root'
})
export class LanguageService implements OnDestroy {

	private availableTranslations: Language[];
	private _subscription = new Subscription();
	private currentLang: string;

	public currentLangChanged = new EventEmitter<string>();

	private storage: Storage;

	constructor(private authService: AuthService,
	            private translate: TranslateService,
	            private localeService: BsLocaleService,
	            private http: HttpClient,
	            private isBrowserService: IsBrowserService) {
		this.storage = new LocalStorage();

		if (isBrowserService.isBrowser) {
			this.storage = localStorage;
		}

		//ngx-bootstrap
		defineLocale('de', deLocale);
		defineLocale('en', enLocale);
		defineLocale('fr', frLocale);

		let localStorageLang = this.storage.getItem('language');

		if (localStorageLang) {
			//use local storage language
			this.currentLang = localStorageLang;
			this.updateExternalPluginLanguage();

		} else {
			//use browser language (or de) as default
			//this.currentLang = navigator.language ? navigator.language.substr(0, 2) : "de";
			//this.updateExternalPluginLanguage();
			const browserLang = translate.getBrowserLang();

			if (browserLang === undefined || ['de', 'en', 'fr'].indexOf(browserLang) == -1) {   //TODO: load possible languages array from environment config.
				this.currentLang = 'en'; //core Fallback
			} else {
				this.currentLang = translate.getBrowserLang();
			}

			this.updateExternalPluginLanguage();

		}
	}

	register() {
		//this._subscription.add(this.authService.currentUserChanged.subscribe(user => this.setSiteLanguage(user.lang)));
	}

	getTranslations(): Observable<Language[]> {
		if (this.availableTranslations == null)
			return this.http.get<Language[]>("/api/translation/customer")
				.pipe(tap(langs => {
					this.availableTranslations = langs;
					if (!this.availableTranslations.find(transl => transl.short == this.currentLang))
						this.currentLang = "de";
				}));
		else
			return of(this.availableTranslations);
	}

	getSiteLanguage() {
		return this.currentLang;
	}

	getSiteLanguageShort() {
		return this.getSiteLanguage().substr(0, 2);
	}

	setSiteLanguage(language: string) {
		this.storage.setItem('language', language);
		this.currentLang = language;
		this.updateExternalPluginLanguage();
	}

	private updateExternalPluginLanguage() {
		const language = this.getSiteLanguageShort();
		const luFallback = this.getLanguageShortWithLuFallback();

		//ngx-translate
		this.translate.use(language);
		this.translate.setDefaultLang(language);

		//moment.js
		moment.locale(luFallback);

		//ngx-bootstrap
		this.localeService.use(luFallback);

		this.currentLangChanged.emit(language);

		/*
		this.translate.use(this.translate.store.currentLang);
		this.translate.store.onLangChange.subscribe((lang) => this.translate.use(lang));
		*/
	}

	public getLanguageShortWithLuFallback() {
		const language = this.getSiteLanguageShort();
		return language == "lu" ? "fr" : language;
	}

	chooseTranslation(value: TranslatedString): string {
		const lang = this.getSiteLanguage();
		const shortLang = lang ? lang.substr(0, 2) : "";
		if (!value)
			return "";

		if (value[lang])
			return value[lang];
		if (value[shortLang])
			return value[shortLang];
		if (value["00"])
			return value["00"];
		if (value.en)
			return value.en;
		const keys = Object.keys(value);
		if (keys.length > 0)
			return value[keys[0]];

		return "";
	}

	ngOnDestroy(): void {
		this._subscription.unsubscribe();
	}
}
