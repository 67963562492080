<div class="content d-flex flex-column flex-column-fluid pb-0 mb-0" id="kt_content">

	<div class="container text-center d-flex flex-column p-10 pb-0 mb-0">

		<div class="mb-5">

			<h1 class="text-center mb-0">{{'job.notFoundTitle' | translate}}</h1>

		</div>
		<div class="d-flex align-items-sm-center flex-column flex-sm-row mb-5 m-auto">

		<span class="align-items-center font-weight-bold">

			<div class="mb-10">
				{{'job.notFoundInfo' | translate}}.
			</div>
			<div class="">
			<span class="py-1 font-size-lg">
				<a class="btn btn-brand btn-lg" routerLink="/job">
					{{'job.backToJobsBtn' | translate}}
				</a>
			</span>

			</div>
		</span>
		</div>
		<div class="svg-wrapper">
			<div class="svg-element">
				<img class="svg" height="500" src="/assets/media/jobportal/illustrations/job-404.svg" width="500">
			</div>
		</div>

	</div>
</div>
