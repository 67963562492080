<app-mobile-header></app-mobile-header>

<div class="d-flex flex-column flex-root" style="position: relative;
	 min-height: 100vh; ">
	<div class="d-flex flex-column flex-root" style="height:100%;">
		<div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
			<app-header></app-header>
			<div style="position: relative;
					 min-height: calc(100vh - 65px);  padding-bottom: 26rem;">
				<router-outlet></router-outlet>
			</div>
			<app-footer style="position: absolute; bottom: 0; width: 100%;"></app-footer> <!--  height: 24.5rem;-->
		</div>
	</div>
</div>
