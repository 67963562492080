import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {CookieService} from "../../../shared/services/cookie.service";
import _ from "lodash";
import {Subscription} from "rxjs";

@Component({
	selector: 'app-cookie-banner',
	templateUrl: './cookie-banner.component.html',
	styleUrls: ['./cookie-banner.component.scss']
})
export class CookieBannerComponent implements OnInit, OnDestroy {

	changeSub$: Subscription;

	formGroup = new FormGroup({
		"marketing": new FormControl(false),
		"personalization": new FormControl(false),
		"analytics": new FormControl(false),
	});

	constructor(public cookies: CookieService) {
	}

	get categories() {
		return Object.keys(this.formGroup.controls);
	}

	ngOnInit(): void {
		this.changeSub$ = this.cookies.changes.subscribe(allowed => this.updateFromAllowed(allowed));
		this.updateFromAllowed(this.cookies.allowedCategories());
	}

	ngOnDestroy(): void {
		this.changeSub$.unsubscribe();
	}

	updateFromAllowed(allowed: string[]) {
		this.formGroup.patchValue({
			..._(this.formGroup.value).pick(_.difference(this.categories, allowed)).mapValues(() => false).value(),
			..._(allowed).keyBy().mapValues(() => true).value()
		});
	}

	save() {
		const allowed = _(this.formGroup.value).pickBy(_.identity).keys().value();
		this.cookies.setAllowed(allowed);
	}

	allowAll() {
		this.cookies.setAllowed(this.categories);
	}
}
