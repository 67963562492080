import {Injectable, OnDestroy, TemplateRef} from '@angular/core';
import {NavigationStart, Router} from "@angular/router";
import {Subscription} from "rxjs";

@Injectable({
	providedIn: 'root'
})
export class OffsidePanelService implements OnDestroy {

	isOpen: boolean = false;
	templateRef: TemplateRef<any>;
	title: String;
	subtitle: String;

	private routerSub$: Subscription;

	constructor(private router: Router) {
		this.routerSub$ = this.router.events.subscribe((e) => {
			if (e instanceof NavigationStart)
				this.close();
		});
	}

	public openWithTemplateRef(ref: TemplateRef<any>, title, subtitle = '') {
		this.templateRef = ref;
		this.isOpen = true;
		this.title = title;
		this.subtitle = subtitle;
	}

	public close() {
		this.isOpen = false;
	}

	ngOnDestroy(): void {
		this.routerSub$.unsubscribe();
	}
}
