import {Component, Input, OnInit} from '@angular/core';
import {NavigationService} from "../../../../shared/services/navigation.service";
import {Navigation} from "../../../../shared/models/navigation.obj";

@Component({
	selector: 'app-footer-navigation',
	templateUrl: './footer-navigation.component.html',
	styleUrls: ['./footer-navigation.component.scss']
})
export class FooterNavigationComponent implements OnInit {

	navigation: Navigation;

	@Input('key') key: string;

	constructor(public navigationService: NavigationService) {
	}

	ngOnInit(): void {

		this.navigationService.getNavigationByKey(this.key).subscribe(data => {
			this.navigation = data;
		});

	}

}
