import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {LanguageService} from "../services/language.service";


@Pipe({
	name: 'salutation'
})
export class SalutationPipe implements PipeTransform {

	constructor(private translate: TranslateService, languageService: LanguageService) {
		/*
				this.translate.use(languageService.getSiteLanguage());

				languageService.currentLangChanged.subscribe(
					(lang) => {
						this.translate.use(lang)
					}
				);

				this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
					// do something
					this.translatedString =  this.translate.instant(this.translatedString); // setting this again
				});
				*/


	}


	transform(gender): string {

		switch (gender) {
			case "m":
			case "male":
			case "männlich":

				return 'salutation.male';
			/*
			this.translate.get('salutation.male').subscribe( (text: string) => {
				return text;
			});*/
			//this.translatedString = this.translate.instant('salutation.male');
			case "w":
			case "f":
			case "female":
			case "weiblich":
				return 'salutation.female';
			case "d":
			case "x":
			case "transgender":
			case "divers":
			case "diverse":
				return 'salutation.diverse';
			//return this.translate.instant('salutation.female');
			default:
				return 'salutation.none';
			//return this.translate.instant('salutation.none');
		}

	}
}
