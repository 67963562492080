import {AfterViewInit, Component, OnInit} from '@angular/core';

@Component({
	selector: 'app-layout',
	templateUrl: './core.component.html',
	styleUrls: ['./core.component.scss']
})
export class CoreComponent implements OnInit, AfterViewInit {

	ngOnInit(): void {
	}

	ngAfterViewInit(): void {
	}

}
