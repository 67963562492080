import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';

@Component({
	selector: 'met-option',
	template: '',
})
export class MetOptionComponent implements MetOption, OnChanges {
	@Input() id: string | number;
	@Input() text: string;
	@Input() additional?: any;
	@Input() disabled: boolean = false;

	@Output() change = new EventEmitter();

	constructor() {
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.change.emit();
	}
}

export interface MetOption {
	id: string | number;
	text: string;
	disabled: boolean;
	additional?: any;
}
