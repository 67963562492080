<div class="header-mobile align-items-center  header-mobile-fixed " id="kt_header_mobile">

	<a routerLink="/">
		<img alt="logo" src="/assets/media/logos/logo.png" style="max-width: 90%; max-height: 40px">
	</a>

	<div class="d-flex align-items-center">
		<button (click)="toggleAside()" class="btn p-0 burger-icon burger-icon-left"
		        id="kt_aside_mobile_toggle">
			<span></span>
		</button>
		<app-language-switch></app-language-switch>
	</div>
</div>

<app-aside-left></app-aside-left>
