import {AbstractControl, FormGroup} from "@angular/forms";
import {ServerError} from "../models/server-error.model";
import {HttpErrorResponse} from "@angular/common/http";
import {Params} from "@angular/router";
import _ from "lodash";

export function getChangedProperties(form: FormGroup) : { [key: string]: any; } {
	let changedProperties = {};

	Object.keys(form.controls).forEach((name) => {
		let currentControl = form.controls[name];
		if(currentControl.dirty) {
			if((currentControl as FormGroup).controls) {
				changedProperties[name] = getChangedProperties(currentControl as FormGroup);
			} else {
				changedProperties[name] = currentControl.value;
			}
		}
	});

	return changedProperties;
}

export function stripNull<T>(formValue: T): Partial<T> {
	let copy = {...formValue};
	for (let key of Object.keys(copy))
		if (copy[key] == null)
			delete copy[key];

	return copy;
}

export function formValueToFormData(formValue: any): FormData {
	let formData = new FormData();
	for (let k of Object.keys(formValue))
		appendToFormData(formData, k, formValue[k]);

	return formData;
}

function appendToFormData(fd: FormData, name: string, value: any): void {
	if (typeof value === 'object' && value !== null) {
		if (value instanceof File) {
			fd.append(name, value, value.name);
		}
		else if (value instanceof Blob) {
			fd.append(name, value);
		}
		else {
			for (let innerName of Object.keys(value))
				appendToFormData(fd, name + "[" + innerName + "]", value[innerName]);
		}
	}
	else if (value === true || value === false) {
		fd.append(name, value ? "1" : "0");
	}
	else {
		fd.append(name, value);
	}
}

export function applyErrorToForms(error: HttpErrorResponse, ...inputForms: AbstractControl[]) {
	if (!error || !inputForms) return;

	let serverError: ServerError = error.error;
	if (serverError.errors) {
		//mark errors in form
		let errorFields = Object.keys(serverError.errors);
		for (let errorField of errorFields) {
			for (let inputForm of inputForms) {
				let errorControl = inputForm.get(errorField);
				if (errorControl)
					errorControl.setErrors({serverError: serverError.errors[errorField]});
			}
		}
	}
}

/**
 * Patches the formGroup's value with the value from the given queryParams
 * @param formGroup
 * @param queryParams
 */
export function patchFromQueryParams(formGroup: FormGroup, queryParams: Params) {
	const patches = {};
	_(queryParams)
		.entries()
		.forEach(entry => _.set(patches, entry[0], entry[1]));

	formGroup.patchValue(patches);
}
