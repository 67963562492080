import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {SettingsService} from "../services/settings.service";

@Injectable({
  providedIn: 'root'
})
export class ShortApplicationRouteGuard  {
	constructor(private settings: SettingsService) {
	}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean {
		return !!this.settings.get('SHORT_APPLICATION_VERSION');
	}

}
