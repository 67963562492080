import {NgModule} from '@angular/core';
import {RouterModule} from "@angular/router";
import {CoreComponent} from "./core.component";
import {NgbDropdownModule, NgbNavModule} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from "@ngx-translate/core";
import {ReactiveFormsModule} from "@angular/forms";
import {UserPanelComponent} from "./components/user-panel/user-panel.component";
import {SharedModule} from "../shared/shared.module";
import {CommonModule} from "@angular/common";
import {OffsidePanelComponent} from "./components/offside-panel/offside-panel.component";
import {LogoSectionComponent} from "./components/logo-section/logo-section.component";
import {AsideLeftComponent} from "./components/aside-left/aside-left.component";
import {BreadcrumbsComponent} from "./components/breadcrumbs/breadcrumbs.component";
import {NotificationListComponent} from "./components/notification-list/notification-list.component";
import {NotificationComponent} from "./components/notification-list/notification/notification.component";
import {LanguageSwitchComponent} from "./components/language-switch/language-switch.component";
import {MobileHeaderComponent} from "./components/header/mobile-header/mobile-header.component";
import {HeaderComponent} from "./components/header/header/header.component";
import {FooterNavigationComponent} from "./components/footer/footer-navigation/footer-navigation.component";
import {FooterComponent} from "./components/footer/footer.component";
import {CookieBannerComponent} from "./components/cookie-banner/cookie-banner.component";
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgScrollbarModule } from 'ngx-scrollbar';


@NgModule({
	declarations: [
		CoreComponent,
		AsideLeftComponent,
		LogoSectionComponent,
		FooterComponent,
		FooterNavigationComponent,
		BreadcrumbsComponent,
		MobileHeaderComponent,
		HeaderComponent,
		OffsidePanelComponent,
		NotificationListComponent,
		NotificationComponent,
		UserPanelComponent,
		LanguageSwitchComponent,
		CookieBannerComponent,
	],
	exports: [
		CookieBannerComponent
	],
	imports: [
		SharedModule,
		CommonModule,
		RouterModule.forRoot([], {scrollPositionRestoration: "top"}),
		NgbDropdownModule,
		NgbNavModule,
		TranslateModule,
		ReactiveFormsModule,
		InlineSVGModule,
		NgScrollbarModule
	]
})
export class CoreModule {
}
