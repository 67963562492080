import {AfterViewInit, Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {NgbModal, NgbModalConfig} from "@ng-bootstrap/ng-bootstrap";
import {isPlatformBrowser} from "@angular/common";

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	providers: [NgbModalConfig, NgbModal]
})
export class AppComponent implements OnInit, AfterViewInit {
	lang = new Subject<string>();
	lang$: Observable<string>;
	title = 'dashboard-app';

	constructor(private translate: TranslateService,
				private ngbModalConfig: NgbModalConfig) {

		// this language will be used as a fallback when a translation isn't found in the current language
		// translate.setDefaultLang('de');

		// the lang to use, if the lang isn't available, it will use the current loader to get them
		// translate.use('de');

		this.setUpNGBConfigs()
	}


	ngOnInit(): void {
	}

	ngAfterViewInit(): void {
	}

	private setUpNGBConfigs() {
		this.ngbModalConfig.scrollable = true;
	}

}
