<div *ngIf="!cookies.bannerHidden()"
     class="position-fixed bottom-0  cookie-wrapper text-white text-inverse-brand w-100 h-auto zindex-2 p-7 d-flex flex-wrap">
	<div class="flex-column" style="flex: 0 0 75%"><!--bg-brand text-inverse-brand-->
		<div>
			{{'footer.cookies.infoText' | translate}}
			<a class="text-white privacy-link"
			   routerLink="/page/privacy-policy">{{'footer.cookies.moreInfoBtn' | translate}}</a>
		</div>
		<div [formGroup]="formGroup" class="d-flex flex-row mt-2">
			<div *ngFor="let category of categories">
				<span class="switch switch-sm switch-icon mr-2 switch-primary switch-outline">
					<label class="d-flex">
						<input [formControlName]="category" metInput type="checkbox"/>
						<span></span>
						<object class="ml-1 my-auto">{{"footer.cookies." + category | translate}}</object>
					</label>
				</span>
			</div>
		</div>
	</div>
	<div class="d-flex flex-row justify-content-center align-items-center" style="flex: 0 0 25%">
		<button (click)="save()" class="btn btn-secondary mr-2 w-50"
		        style="flex: 50%">{{'footer.cookies.saveBtn' | translate}}</button>
		<button (click)="allowAll()" class="btn btn-primary"
		        style="flex: 50%">{{'footer.cookies.saveAllBtn' | translate}}</button>
	</div>
</div>
