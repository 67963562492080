import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {LayoutService} from "../../layout.service";
import {SettingsService} from "../../../shared/services/settings.service";
import {IsBrowserService} from "../../../shared/services/isBrowser.service";


@Component({
	selector: 'app-aside-left',
	templateUrl: './aside-left.component.html',
	styleUrls: ['./aside-left.component.scss']
})

export class AsideLeftComponent implements OnInit, AfterViewInit {
	@ViewChild('asideMenu') asideMenu;
	isBrowser: boolean = false;

	menuitems;

	constructor(public layoutService: LayoutService, public settings: SettingsService, private isBrowserService: IsBrowserService) {
	}

	ngOnInit(): void {
		console.log('aside-left component ngOnInit was called');

		this.menuitems = [
			{
				"name": "header.Home",
				"url": "/home",
				"icon": "la-home"
			},
			{
				"name": "header.Jobs",
				"icon": "la-briefcase",
				"url": "/job"
			},
			{
				"name": "header.SpontaneousApplication",
				"icon": "la-address-card",
				"url": "/job-spontaneous"
			},


		];

		if (this.settings.get('SHOW_APPLY_TIPS')) {
			this.menuitems.push({
				"name": "header.HowToApply",
				"icon": "la-question-circle",
				"url": "/page/how-to-apply"
			});
		}

		if(this.isBrowserService.isBrowser)
				this.isBrowser = true;
	}

	ngAfterViewInit(): void {
		console.log('aside-left component ngAfterViewInit was called, isBrowser?', this.isBrowser);

		if(this.isBrowser)
			new KTMenu(this.asideMenu.nativeElement, {
				// submenu setup
				submenu: {
					desktop: {
						// by default the menu mode set to accordion in desktop mode
						default: 'accordion',
					},
					tablet: 'accordion', // menu set to accordion in tablet mode
					mobile: 'accordion' // menu set to accordion in mobile mode
				},

				// accordion setup
				accordion: {
					expandAll: false // allow having multiple expanded accordions in the menu
				}
			});
	}

	toggleAsideClick() {
		this.layoutService.asideOn = !this.layoutService.asideOn;
		//document.body.classList.toggle('aside-minimize');
	}
}
