import {Injectable} from '@angular/core';
import {Observable, of} from "rxjs";
import {Job} from "../models/job.obj";
import {HttpClient} from "@angular/common/http";
import {Paginated} from "../models/paginated.obj";
import {filter, map} from "rxjs/operators";

@Injectable({
	providedIn: 'root'
})
export class JobService {

	constructor(private http: HttpClient) {
	}

	getJobList(page: number, filters: JobFilters = {}, count = 10, sort = "-publishDate"): Observable<Paginated<Job>> {
		let internalFilters = {};
		for (let [key, value] of Object.entries(filters)) {
			if (Array.isArray(value) && value.length > 0)
				internalFilters['filter[' + key + ']'] = value.join(",");
			else if (value != null)
				internalFilters['filter[' + key + ']'] = value;
		}

		return this.http.get<Paginated<Job>>("/api/jobportal/jobs", {
			params: {
				page: page + "",
				count: count + "",
				sort,
				...internalFilters
			}
		});
	}

	getJobsFromCompany(company_id: number, count: number, exclude_job_id = null): Observable<Job[]> {
		return this.getJobList(1, {
			company: [company_id]
		}, count).pipe(map(jobs => jobs.data.filter(job => !exclude_job_id || job.id != exclude_job_id)));
	}

	getJobByID(id: number | string): Observable<Job> {
		if (typeof id == "number") {
			return this.http.get<Job>("/api/jobportal/job/public/" + id);
		} else {
			const split = (<string>id).split("-");
			const actualID = parseInt(split[0]);
			if (isNaN(actualID))
				return of(null);

			return this.getJobByID(actualID);
		}
	}
}

export type JobFilters = Partial<{
	title: string;
	type: number[];
	industries: number[];
	country: string[];
	company: number[];
	archived: boolean;
	date: string;
}>
