import {Injectable, TemplateRef} from '@angular/core';
import {ServerError} from "../models/server-error.model";
import {FormGroup} from "@angular/forms";
import {HttpErrorResponse} from "@angular/common/http";
import {applyErrorToForms} from "../functions/formgroup";

export class SnackBarObject<T = string | TemplateRef<any>> {
	title?: string;
	textOrTpl: T;
	class?: string; // snackbar-success, snackbar-error, ...
	delay?: number;
	list?: string[];
}

@Injectable({
	providedIn: 'root'
})
export class SnackBarService {

	_toasts: SnackBarObject[] = [];

	constructor() {
	}

	show(sbo: SnackBarObject) {
		this._toasts.push(sbo);
	}

	remove(sbo: SnackBarObject) {
		this._toasts = this._toasts.filter(t => t !== sbo);
	}

	showSuccessDialog(dialog: string | TemplateRef<any>) {
		this.show({ textOrTpl: dialog, class: "toast-success"});
	}

	showErrorDialog(dialog: string | TemplateRef<any>) {
		this.show({ textOrTpl: dialog, class: "toast-error"});
		// this.snackBar.open(text, "Ausblenden", {panelClass: "snackbar-error", duration: 5000, verticalPosition: "top"});
	}

	showErrorDialogWithList(text: string, list: string[]) {
		this.show({ textOrTpl: text, class: "toast-error", list});
		// this.snackBar.openFromComponent(SnackBarErrorListComponent, {panelClass: "snackbar-error", duration: 5000, verticalPosition: "top"});
	}

	/**
	 * Shows an error snackbar with the list of errors that were returned by the backend server.
	 * @param text The error text to show.
	 * @param error The error response returned by the server.
	 * @param inputForm Optional FormGroup, if given, errors are marked in the form.
	 */
	showServerError(text: string, error: HttpErrorResponse, inputForm?: FormGroup) {
		let serverError: ServerError = error.error;
		if (serverError.errors) {
			//flatten the inner array
			let errorTexts = Object.values(serverError.errors).reduce((acc, val) => acc.concat(val), []);

			this.showErrorDialogWithList(text, errorTexts);

			if (inputForm)
				applyErrorToForms(error, inputForm);
		} else {
			this.showErrorDialog(text);
		}
	}
}
