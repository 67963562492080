<div class="header header-fixed" id="kt_header">
	<!--begin::Container-->
	<div class="container d-flex align-items-stretch justify-content-between">
		<!--begin::Header Menu Wrapper-->
		<div class="header-menu-wrapper header-menu-wrapper-left" id="kt_header_menu_wrapper">
			<!--begin::Header Logo-->
			<div class="header-logo">
				<a href="/">
					<img alt="Logo" src="/assets/media/logos/logo.png">
				</a>
			</div>

			<!--end::Header Logo-->
			<!--begin::Header Menu-->
			<div class="header-menu header-menu-mobile header-menu-layout-default" id="kt_header_menu">
				<!--begin::Header Nav-->
				<ul class="menu-nav">
					<li aria-haspopup="true" class="menu-item menu-item-submenu menu-item-rel"
					    data-menu-toggle="click" routerLinkActive="menu-item-active">
						<a class="menu-link menu-toggle" routerLink="/home">
							<span class="menu-text">{{'header.Home' | translate}}</span>
							<i class="menu-arrow"></i>
						</a>
					</li>
					<li aria-haspopup="true" class="menu-item menu-item-submenu menu-item-rel"
					    data-menu-toggle="click" routerLinkActive="menu-item-active">
						<a class="menu-link menu-toggle" routerLink="/job">
							<span class="menu-text">{{'header.Jobs' | translate}}</span>
							<i class="menu-arrow"></i>
						</a>
					</li>
					<li aria-haspopup="true" class="menu-item menu-item-submenu menu-item-rel"
					    data-menu-toggle="click" routerLinkActive="menu-item-active">
						<a class="menu-link menu-toggle" [routerLink]="shortApplication ? '/job-spontaneous/short' : '/job-spontaneous'">
							<span class="menu-text">{{'header.SpontaneousApplication' | translate}}</span>
							<i class="menu-arrow"></i>
						</a>
					</li>
					<li *ngIf="settings.get('SHOW_APPLY_TIPS')" aria-haspopup="true"
					    class="menu-item menu-item-submenu menu-item-rel" data-menu-toggle="click" routerLinkActive="menu-item-active">
						<a class="menu-link menu-toggle" routerLink="/page/how-to-apply">
							<span class="menu-text">{{'header.HowToApply' | translate}}</span>
							<i class="menu-arrow"></i>
						</a>
					</li>
				</ul>
				<!--end::Header Nav-->
			</div>
			<!--end::Header Menu-->
		</div>
		<!--end::Header Menu Wrapper-->
		<!--begin::Topbar-->

		<!--<app-breadcrumbs></app-breadcrumbs> -->

		<div class="topbar">

			<app-language-switch></app-language-switch>

		</div>
		<!--end::Topbar-->
	</div>
	<!--end::Container-->
</div>
