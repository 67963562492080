import {BrowserModule} from '@angular/platform-browser';
import {DEFAULT_CURRENCY_CODE, ErrorHandler, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NotFoundComponent} from './core/error-pages/not-found/not-found.component';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';
import {CoreModule} from "./core/core.module";
import {ForbiddenComponent} from "./core/error-pages/forbidden/forbidden.component";
import {SharedModule} from "./shared/shared.module";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {JWTInterceptor} from "./shared/interceptors/jwt.interceptor";
import {ErrorInterceptor} from "./shared/interceptors/error.interceptor";
import {ApiUrlInterceptor} from "./shared/interceptors/apiurl.interceptor";
import {BsDatepickerConfig, BsDatepickerModule} from "ngx-bootstrap/datepicker";
import {getDatepickerConfig} from "./shared/functions/dates";
import {BsDropdownModule} from "ngx-bootstrap/dropdown";
import {LanguageService} from "./shared/services/language.service";
import {TimeConfig} from "./shared/classes/time-config";
import {AccordionModule} from "ngx-bootstrap/accordion";
import {MultiTranslateHttpLoader} from "ngx-translate-multi-http-loader";
import {AngularUniversalInterceptor} from "./shared/interceptors/angular-universal.interceptor";
import * as Sentry from "@sentry/angular";
import {SentryErrorHandler} from "./shared/interceptors/sentryerror.handler";
import {InlineSVGModule} from "ng-inline-svg-2";

@NgModule({
	declarations: [
		AppComponent,
		NotFoundComponent,
		ForbiddenComponent,
	],
	imports: [
		BrowserModule.withServerTransition({appId: 'serverApp'}),
		BrowserAnimationsModule,
		HttpClientModule,
		FormsModule,
		AppRoutingModule,
		BsDatepickerModule.forRoot(),
		BsDropdownModule.forRoot(),
		AccordionModule.forRoot(),
		InlineSVGModule.forRoot(),

		// ngx-translate and the loader module
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			},
			isolate: false

		}),

		CoreModule,
		SharedModule
	],
	exports: [
		TranslateModule,
	],
	providers: [
		{provide: HTTP_INTERCEPTORS, useClass: JWTInterceptor, multi: true},
		{provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
		{provide: HTTP_INTERCEPTORS, useClass: ApiUrlInterceptor, multi: true},
		{provide: HTTP_INTERCEPTORS, useClass: AngularUniversalInterceptor, multi: true},
		{provide: DEFAULT_CURRENCY_CODE, useValue: "EUR"},
		{provide: BsDatepickerConfig, useFactory: getDatepickerConfig},
		{provide: TimeConfig, useValue: {startHours: 7, endHours: 20, daysOfWeek: [1, 2, 3, 4, 5]}},
		{provide: ErrorHandler, useFactory: SentryErrorHandler.initWith(Sentry)},
	],
	bootstrap: [AppComponent]
})
export class AppModule {
	constructor(private languageService: LanguageService) {
		languageService.register();
	}
}

// Using HTTPBackend to ignore interceptors as language files are not part of the api url
// required for AOT

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
	return new MultiTranslateHttpLoader(http, [
		{prefix: '/assets/i18n/', suffix: '.json'}
	]);
}

/*
export function HttpLoaderFactory(httpBackend: HttpBackend) {
	let http = new HttpClient(httpBackend);
	return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}
*/
