import {Component, Input, OnInit} from '@angular/core';
import {Job} from "../../../models/job.obj";
import {NavigationItem} from "../../../models/navigation.obj";

@Component({
	selector: 'app-navigation-item',
	templateUrl: './navigation-item.component.html',
	styleUrls: ['./navigation-item.component.scss']
})
export class NavigationItemComponent implements OnInit {

	@Input('navigationItem') item: NavigationItem;

	encodedEmail: string = null;
	emailLink:string;



	constructor() {
	}

	ngOnInit(): void {

		if(this.item.type == 'customLink') {
			//check if mailto is included:
			if (this.item.url.includes('mailto:')) {
					this.item.url = this.item.url.replace("mailto:", "");
				this.encodedEmail = this.item.url.replace(/[^]/g, function (e) {
					return "&#" + e.charCodeAt(0) + ";"
				});
				this.emailLink = '<a rel="nofollow" class="nav-link pl-0 pr-5" href="mailto:' + this.encodedEmail + '">' + this.encodedEmail + '</a>';
			}

		}
	}

	private emailIsValid(email) {
		return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
	}

}
