import {EventEmitter, Injectable} from '@angular/core';
import {LocalStorage} from '../models/local-storage.obj';
import {IsBrowserService} from "./isBrowser.service";

/**
 * This service handles the user's cookie preferences.
 */
@Injectable({
	providedIn: 'root'
})
export class CookieService {

	private static storageItem = "allowedCookies";
	private static storageSavedItem = "allowedCookiesSaved";
	private storage: Storage;

	changes = new EventEmitter<string[]>();

	constructor(private isBrowserService: IsBrowserService) {
		this.storage = new LocalStorage();
		if (isBrowserService.isBrowser) {
			this.storage = localStorage;
		}
	}

	/**
	 * This overwrites all cookie choices and sets the given `categories` as the only allowed ones
	 */
	public setAllowed(categories: string[]): void {
		if (categories == null)
			this.storage.removeItem(CookieService.storageItem);
		else
			this.storage.setItem(CookieService.storageItem, categories.join(","));

		this.storage.setItem(CookieService.storageSavedItem, "true");
		this.changes.emit(categories);
	}

	/**
	 * This saves the given `category` as allowed
	 */
	public allowCategory(category: string): void {
		const allowed = this.allowedCategories() || [];
		if (allowed.indexOf(category) < 0) {
			allowed.push(category);
			this.setAllowed(allowed);
		}
	}

	/**
	 * This saves the given `category` as disallowed
	 */
	public disallowCategory(category: string): void {
		const allowed = this.allowedCategories() || [];
		const index = allowed.indexOf(category);
		if (index >= 0) {
			allowed.splice(index, 1);
			this.setAllowed(allowed);
		}
	}

	/**
	 * Whether the user has saved his preferences
	 */
	public bannerHidden(): boolean {
		return this.storage.getItem(CookieService.storageSavedItem) == "true";
	}

	/**
	 * Returns true if the given `category` was allowed by the user.
	 */
	public isAllowed(category: string): boolean {
		return this.allowedCategories().indexOf(category) >= 0;
	}

	/**
	 * Returns all cookie categories the user allowed
	 */
	public allowedCategories(): string[] {
		let cookieSetting = this.storage.getItem(CookieService.storageItem);
		if (cookieSetting == null)
			return [];

		return cookieSetting.split(",");
	}

	showBanner(show: boolean) {
		this.storage.setItem(CookieService.storageSavedItem, String(show));
		this.changes.emit(this.allowedCategories());
	}
}
