import {ElementRef} from '@angular/core';
import {AbstractControl, NgControl, ValidationErrors} from "@angular/forms";

export class ErrorContainerOwner {
	errorContainer: ErrorContainer;
}

export class ErrorContainer {

	get errors(): ValidationErrors | null {
		if (this.ngControl)
			return this.ngControl.errors;
		return null;
	}

	get showErrors(): boolean {
		if (!this.ngControl)
			return false;
		return this.ngControl.invalid && (this.ngControl.touched || this.ngControl.dirty);
	}

	updateElementValidity() {
		if (this.showErrors) {
			const elem: HTMLElement = this._elementRef.nativeElement;
			const wasAlreadyInvalid = elem.classList.contains("is-invalid");
			elem.classList.add("is-invalid");
			// if (!wasAlreadyInvalid)
			// 	elem.scrollIntoView({
			// 		block: "center",
			// 		behavior: "smooth"
			// 	});
		}
		else this._elementRef.nativeElement.classList.remove("is-invalid");
	}

	constructor(protected _elementRef: ElementRef,
	            public ngControl?: AbstractControl | NgControl) {
	}

}
