import {Injectable} from '@angular/core';
import {SettingsService} from "./settings.service";

@Injectable({
	providedIn: 'root'
})
export class BrandCompanyService {

	constructor(private settings: SettingsService) {
	}

	isSet(): boolean {
		return !!this.getID();
	}

	/**
	 * Returns the id of the company whose job portal this is,
	 * or null if this portal is meant for multiple companies
	 */
	getID(): number {
		return this.settings.get("PROJECT_COMPANY_ID");
	}
}
