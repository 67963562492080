import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	Injector,
	Input,
	NgZone,
	OnChanges,
	OnInit,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import {FileChooserDirective} from "../../directives/file-chooser.directive";
import {FileObj} from "../../models/file-obj.model";
import {blobToDataURL} from "../../functions/images";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl} from "@angular/forms";
import {ErrorContainer, ErrorContainerOwner} from "../../classes/error-container";
import {IsBrowserService} from "../../services/isBrowser.service";

@Component({
	selector: 'met-file-input',
	templateUrl: './met-file-input.component.html',
	styleUrls: ['./met-file-input.component.scss'],
	providers: [
		{provide: ErrorContainerOwner, useExisting: MetFileInputComponent},
		{provide: NG_VALUE_ACCESSOR, useExisting: MetFileInputComponent, multi: true},
	]
})
export class MetFileInputComponent implements OnInit, AfterViewInit, OnChanges, ControlValueAccessor {
	@Input() accept: string;
	@Input() maxSize: string = null; //kb format
	@Input() previous: FileObj;
	@Input() preview: boolean = false;
	@Input() style: string;

	@Input() dotPipeLength: number = 20;

	@ViewChild("file") fileChooser: FileChooserDirective;
	@ViewChild("inputElement") input: ElementRef<HTMLInputElement>;
	private static currentID = 0;
	id: string;
	change = new EventEmitter<Event>();

	previewURL: SafeResourceUrl;

	errorContainer: ErrorContainer;
	_onChange: Function;
	_onTouched: Function;

	isBrowser: boolean = false;

	constructor(private zone: NgZone,
	            private sanitizer: DomSanitizer,
	            private injector: Injector,
	            private isBrowserService: IsBrowserService) {
		this.id = "file_input_" + MetFileInputComponent.currentID++;
	}

	ngOnInit(): void {
		this.isBrowser = this.isBrowserService.isBrowser;
	}

	ngAfterViewInit() {
		const ngControl = this.injector.get<NgControl>(NgControl, null);
		this.errorContainer = new ErrorContainer(this.input, ngControl);
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.previous) {
			this.updatePreview();
		}
	}

	_getFileNameToDisplay() {
		if (this.fileChooser != null) {
			const actualFile = this.fileChooser.getFile();
			if (actualFile != null)
				return actualFile.name;
		}
		if (this.previous != null)
			return this.previous.originalName;
		else
			return null;
	}

	public getValue() {
		if (!this.fileChooser) return null;

		return this.fileChooser.getValue();
	}

	public addTo(formValue: any, name: string): FormData {
		return this.fileChooser.addTo(formValue, name);
	}

	public addToFormData(formData: FormData, name: string): FormData {
		return this.fileChooser.addToFormData(formData, name);
	}

	selectFile(event, item) {
		if (this.isBrowser) {
			let element: HTMLElement = document.querySelector('#' + item.id) as HTMLElement;
			element.click();
		}
	}

	clear() {
		this.fileChooser.clearSelection();
	}

	filesChanged() {
		if (this._onChange)
			this._onChange(this.getValue());
		this.updatePreview();
	}

	updatePreview() {
		if (!this.preview)
			return;

		const value = this.getValue();
		if (value instanceof File) {
			blobToDataURL(value).then(dataURL =>
				this.zone.run(() => {
					//this is safe because the user loads it himself
					this.previewURL = this.sanitizer.bypassSecurityTrustResourceUrl(dataURL);
				})
			);
		} else if (value == null) {
			if (this.previous != null)
				this.previewURL = this.previous.url;
			else
				this.previewURL = null;
		}
	}

	registerOnChange(fn: any): void {
		this._onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this._onTouched = fn;
	}

	writeValue(obj: any): void {
		// cannot set value if file input
		//TODO: allow setting null?
	}

	touched() {
		if (this._onTouched)
			this._onTouched();
	}
}
