import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FullNamePipe} from './pipes/full-name.pipe';
import {UserFirstLetterPipe} from './pipes/first-letter.pipe';
import {ThreeDotsPipe} from "./pipes/slicestr.pipe";
import {ChooseTranslationPipe} from './pipes/i18n/choose-translation.pipe';
import {LanguageNamePipe} from "./pipes/i18n/language-name.pipe";
import {TranslateModule} from "@ngx-translate/core";
import {FormComponent} from "./components/form/form.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HasPermissionDirective} from "./directives/hasPermission.directive";
import {FormFieldComponent} from './components/form-field/form-field.component';
import {FormInputDirective} from './directives/form-input.directive';
import {FormErrorComponent} from "./components/form-field/form-error.component";
import {FormHintComponent} from "./components/form-field/form-hint.component";
import {FormLabelComponent} from "./components/form-field/form-label.component";
import {NgSelect2Module} from 'ng-select2';
import {MetSelectComponent} from './components/met-select/met-select.component';
import {MetOptionGroupComponent} from './components/met-select/met-option-group.component';
import {MetOptionComponent} from "./components/met-select/met-option.component";
import {SnackbarContainerComponent} from './components/snackbar-container/snackbar-container.component';
import {NgbPopoverModule, NgbToastModule, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {BsDatepickerModule} from "ngx-bootstrap/datepicker";
import {UserCardComponent} from './components/user-card/user-card.component';
import {AgePipe} from './pipes/age.pipe';
import {FormatDateTimePipe} from "./pipes/i18n/formatDateTime.pipe";
import {FromNowPipe} from "./pipes/i18n/fromNow.pipe";
import {DatepickerRepeatingComponent} from './components/datepicker-repeating/datepicker-repeating.component';
import {HasNotAllPermissionsDirective} from "./directives/has-not-all-permissions.directive";
import {FormatMeetingPipe} from "./pipes/i18n/format-meeting.pipe";
import {FormatDatePipe} from "./pipes/i18n/formatDate.pipe";
import {SubheaderComponent} from "./components/subheader/subheader.component";
import {KtPortletComponent} from './components/kt-portlet/kt-portlet.component';
import {KtPortletHeaderComponent} from './components/kt-portlet/kt-portlet-header/kt-portlet-header.component';
import {KtPortletToolsComponent} from './components/kt-portlet/kt-portlet-tools/kt-portlet-tools.component';
import {KtPortletBodyComponent} from './components/kt-portlet/kt-portlet-body/kt-portlet-body.component';
import {KtCalloutComponent} from './components/kt-callout/kt-callout.component';
import {KtUserProfile3Component} from "./components/kt-user-profile3/kt-user-profile3.component";
import {KtTabsComponent} from './components/kt-tabs/kt-tabs.component';
import {KtTabPaneComponent} from './components/kt-tabs/kt-tab-pane/kt-tab-pane.component';
import {MultiLanguageInputComponent} from "./components/multi-language-input/multi-language-input.component";
import {FormatTimePipe} from "./pipes/i18n/formatTime.pipe";
import {FormatCurrencyPipe} from "./pipes/i18n/format-currency.pipe";
import {PercentPipe} from "./pipes/percent.pipe";
import {TimepickerComponent} from "./components/timepicker/timepicker.component";
import {PopupTooltipComponent} from './components/popup-tooltip/popup-tooltip.component';
import {FileChooserDirective} from './directives/file-chooser.directive';
import {ExternalLinkPipe} from './pipes/external-link.pipe';
import {MetFileInputComponent} from './components/met-file-input/met-file-input.component';
import {FileChooserLabelComponent} from './components/file-chooser-label/file-chooser-label.component';
import {SelectModalComponent} from "./components/modal/select-modal/select-modal.component";
import {ModalComponent} from "./components/modal/modal/modal.component";
import {AvatarListComponent} from './components/avatar-list/avatar-list.component';
import {RemoveDialogComponent} from "./components/modal/remove-dialog/remove-dialog.component";
import {LeftPadPipe} from './pipes/left-pad.pipe';
import {MapPipe} from './pipes/map.pipe';
import {JoinPipe} from './pipes/join.pipe';
import {LocationPipe} from "./pipes/location.pipe";
import {BackButtonDirective} from "./directives/backButton.directive";
import {ImageSelectComponent} from './components/image-select/image-select.component';
import {TableactionsComponent} from './components/tableactions/tableactions.component';
import {GenderIconPipe} from "./pipes/gender-icon.pipe";
import {TableNoValuePipe} from "./pipes/table-no-value.pipe";
import {PatientStatusLabelPipe} from "./pipes/patient-status-label.pipe";
import {AvatarCardComponent} from './components/avatar-card/avatar-card.component';
import {ClipboardModalComponent} from './components/modal/clipboard-modal/clipboard-modal.component';
import {ConfirmModalComponent} from './components/modal/confirm-modal/confirm-modal.component';
import {DateRangePickerComponent} from './components/date-range-picker/date-range-picker.component';
import {DateRangePopoverComponent} from './components/date-range-popover/date-range-popover.component';
import {StatusLabelPipe} from "./pipes/status-label.pipe";
import {UpperFullNamePipe} from './pipes/upper-full-name.pipe';
import {ProfileSummaryComponent} from './components/profile-summary/profile-summary.component';
import {AvatarComponent} from './components/avatar/avatar.component';
import {AgeCommaMonthPipe} from './pipes/age-comma-month.pipe';
import {JobItemComponent} from "./components/job-item/job-item.component";
import {InfoIconComponent} from "./components/info-icon/info-icon.component";
import {SocialShareComponent} from "./components/social-share/social-share.component";
import {SalutationPipe} from "./pipes/salutation.pipe";
import {KtAccordionComponent} from './components/kt-accordion/kt-accordion.component';
import {KtAccordionPageComponent} from './components/kt-accordion/kt-accordion-page/kt-accordion-page.component';
import {KtPaginationComponent} from './components/kt-pagination/kt-pagination.component';
import {MomentModule} from "ngx-moment";
import {JobLinkPipe} from './pipes/job-link.pipe';
import {CountryNamePipe} from "./pipes/country-name.pipe";
import {LoadingDirective} from "./directives/loading.directive";
import {BannerImageComponent} from "./components/banner-image/banner-image.component";
import {CollapseModule} from "ngx-bootstrap/collapse";
import {NavigationItemComponent} from './components/footer/navigation-item/navigation-item.component';
import {SocialButtonsComponent} from './components/social-buttons/social-buttons.component';
import {StripHtmlPipe} from "./pipes/stripHtml.pipe";
import {CheckboxListComponent} from './components/checkbox-list/checkbox-list.component';
import {NgSelectModule} from "@ng-select/ng-select";
// import {DragulaModule} from "ng2-dragula";

//import { SlickCarouselModule } from 'ngx-slick-carousel';

@NgModule({
	declarations: [
		AgePipe,
		FullNamePipe,
		UserFirstLetterPipe,
		ThreeDotsPipe,
		LanguageNamePipe,
		ChooseTranslationPipe,
		FormatDateTimePipe,
		FormatMeetingPipe,
		FromNowPipe,
		FormatDatePipe,
		FormatTimePipe,
		LocationPipe,
		TableNoValuePipe,
		PatientStatusLabelPipe,
		StatusLabelPipe,
		SalutationPipe,
		CountryNamePipe,

		HasPermissionDirective,
		HasNotAllPermissionsDirective,
		FormInputDirective,
		LoadingDirective,

		FormComponent,
		RemoveDialogComponent,
		UserCardComponent,
		FormFieldComponent,
		FormErrorComponent,
		FormHintComponent,
		FormLabelComponent,
		MetSelectComponent,
		MetOptionGroupComponent,
		MetOptionComponent,
		SnackbarContainerComponent,
		DatepickerRepeatingComponent,
		SubheaderComponent,
		KtPortletComponent,
		KtPortletHeaderComponent,
		KtPortletToolsComponent,
		KtPortletBodyComponent,
		KtCalloutComponent,
		KtUserProfile3Component,
		KtTabsComponent,
		KtTabPaneComponent,
		MultiLanguageInputComponent,
		PercentPipe,
		FormatCurrencyPipe,
		FormatMeetingPipe,
		TimepickerComponent,
		FileChooserDirective,
		MetFileInputComponent,
		PopupTooltipComponent,
		TableactionsComponent,
		ExternalLinkPipe,
		FileChooserLabelComponent,
		ModalComponent,
		SelectModalComponent,
		AvatarListComponent,
		LeftPadPipe,
		MapPipe,
		JoinPipe,
		LocationPipe,
		GenderIconPipe,
		BackButtonDirective,
		ImageSelectComponent,
		AvatarCardComponent,
		ClipboardModalComponent,
		ConfirmModalComponent,
		UpperFullNamePipe,
		AgeCommaMonthPipe,
		ProfileSummaryComponent,
		AvatarComponent,
		DateRangePickerComponent,
		DateRangePopoverComponent,

		JobItemComponent,
		InfoIconComponent,
		SocialShareComponent,
		KtAccordionComponent,
		KtAccordionPageComponent,
		KtPaginationComponent,
		JobLinkPipe,
		BannerImageComponent,
		NavigationItemComponent,
		SocialButtonsComponent,
		StripHtmlPipe,
		CheckboxListComponent

	],
	imports: [
		CommonModule,
		RouterModule,
		FormsModule,
		TranslateModule,

		NgSelect2Module,
		ReactiveFormsModule,
		NgbToastModule,
		BsDatepickerModule,
		NgbTooltipModule,

		NgbPopoverModule,
		MomentModule,
		CollapseModule,
		NgSelectModule,
		// DragulaModule,
		//SlickCarouselModule

	],
	exports: [

		//Modules
		TranslateModule,
		//SlickCarouselModule,

		//Directives
		HasPermissionDirective,
		FormInputDirective,
		HasNotAllPermissionsDirective,
		HasPermissionDirective,
		BackButtonDirective,
		LoadingDirective,

		// Pipes
		AgePipe,
		FullNamePipe,
		UserFirstLetterPipe,
		LanguageNamePipe,
		ThreeDotsPipe,
		ChooseTranslationPipe,
		FormatDateTimePipe,
		FromNowPipe,
		FormatDatePipe,
		FormatTimePipe,
		FormatCurrencyPipe,
		PercentPipe,
		FormatMeetingPipe,
		LocationPipe,
		GenderIconPipe,
		TableNoValuePipe,
		PatientStatusLabelPipe,
		StatusLabelPipe,
		SalutationPipe,
		CountryNamePipe,

		//Components
		RemoveDialogComponent,
		FormComponent,
		FormErrorComponent,
		FormHintComponent,
		FormFieldComponent,
		FormLabelComponent,
		MetSelectComponent,
		MetOptionComponent,
		MetOptionGroupComponent,
		SnackbarContainerComponent,
		UserCardComponent,
		DatepickerRepeatingComponent,
		SubheaderComponent,
		KtPortletComponent,
		KtPortletHeaderComponent,
		KtPortletToolsComponent,
		KtPortletBodyComponent,
		KtCalloutComponent,
		KtUserProfile3Component,
		KtTabPaneComponent,
		KtTabsComponent,
		TimepickerComponent,
		MultiLanguageInputComponent,
		PopupTooltipComponent,
		FileChooserDirective,
		ExternalLinkPipe,
		FileChooserLabelComponent,
		TableactionsComponent,
		ModalComponent,
		MetFileInputComponent,
		AvatarListComponent,
		MapPipe,
		JoinPipe,
		LocationPipe,
		ImageSelectComponent,
		AvatarCardComponent,
		UpperFullNamePipe,
		AgeCommaMonthPipe,
		AvatarComponent,
		ProfileSummaryComponent,
		DateRangePickerComponent,
		DateRangePopoverComponent,

		JobItemComponent,
		InfoIconComponent,
		SocialShareComponent,
		KtAccordionComponent,
		KtAccordionPageComponent,
		KtPaginationComponent,
		BannerImageComponent,
		NavigationItemComponent,
		SocialButtonsComponent,
		StripHtmlPipe,
		CheckboxListComponent
	]
})
export class SharedModule {
}
