import {Component, ContentChild, ContentChildren, QueryList} from '@angular/core';
import {FormHintComponent} from "./form-hint.component";
import {FormErrorComponent} from "./form-error.component";
import {ErrorContainerOwner} from "../../classes/error-container";

@Component({
	selector: 'met-form-field',
	templateUrl: './form-field.component.html',
	styleUrls: ['./form-field.component.scss']
})
export class FormFieldComponent {
	@ContentChildren(ErrorContainerOwner, {descendants: true}) inputs!: QueryList<ErrorContainerOwner>;

	@ContentChild(FormHintComponent) hint: FormHintComponent;
	@ContentChild(FormErrorComponent) error: FormErrorComponent;

	get showErrors(): boolean {
		if (!this.inputs)
			return false;
		const errorContainers = this.inputs.filter(i => !!i.errorContainer).map(i => i.errorContainer);
		if (errorContainers.length == 0)
			return false;
		//show error marking on inputs
		errorContainers.forEach(e => e.updateElementValidity());
		//show error message, if one has errors
		return !!errorContainers.find(e => e.showErrors);
	}

	constructor() {
	}

}
