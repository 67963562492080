import {Pipe, PipeTransform} from '@angular/core';
import {Country} from "../models/country.model";

@Pipe({
	name: 'countryName'
})
export class CountryNamePipe implements PipeTransform {

	transform(id: number, countries: Country[]): any {

		if (!id)
			return;
		let country = countries.find(x => x.id == id);
		if (country)
			return country.countryName;

		return null;
	}

}
