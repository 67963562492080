import {Component, OnDestroy, OnInit} from '@angular/core';
import {LanguageService} from "../../../shared/services/language.service";
import {Language} from "../../../shared/models/language.model";
import {Subscription} from "rxjs";

@Component({
	selector: 'app-language-switch',
	templateUrl: './language-switch.component.html',
	styleUrls: ['./language-switch.component.scss']
})
export class LanguageSwitchComponent implements OnInit, OnDestroy {

	systemLanguages: Language[];

	private languageServiceGetTranslations$: Subscription;

	constructor(public languageService: LanguageService) {
	}

	ngOnInit(): void {
		this.languageServiceGetTranslations$ = this.languageService.getTranslations().subscribe(translations => {
			this.systemLanguages = translations;
		});
	}

	countryToFileName(short: string) {

		switch (short) {
			case "de":
				return "162-germany.svg";
			case "en":
				return "260-united-kingdom.svg";
			case "fr":
				return "195-france.svg";
			case "lu":
				return "035-luxembourg.svg";
			default:
				return "";
		}
	}

	changeLanguage(lang: Language) {
		this.languageService.setSiteLanguage(lang.short);
	}

	ngOnDestroy(): void {
		this.languageServiceGetTranslations$.unsubscribe();
	}
}
