import {Component} from '@angular/core';

@Component({
	selector: 'met-hint',
	template: '<span class="form-text text-muted"><ng-content></ng-content></span>'
})
export class FormHintComponent {

	constructor() {
	}

}
