<div class="footer bg-white py-4 d-flex flex-lg-column" id="kt_footer" style="z-index: 1000">
	<div class="container">

		<div class="row">
			<div class="col-12 my-6">
				<div class="row">
					<div class="col-lg-5">
						<div class="row">
							<div class="col-lg-12">
								<img alt="Logo" class="footer-logo h-25px" src="/assets/media/logos/logo.png">
								<!--<img src="/assets/media/logos/cuco-logo-bird-dark.svg" class="w-25px">-->
							</div>
						</div>
						<!--
						<div class="row mt-5 mb-10">
							<div class="col-lg-6">
								<span>cuco Jobster has a collection of latest 2,500 jobs to join next companies.</span> -
							</div>
						</div>
						-->
					</div>
					<div class="col-lg-7">
						<div *ngIf="dynamicFooterNavigation" class="row">

							<app-footer-navigation [key]="'footer-left'" class="col-lg-4 mb-10"></app-footer-navigation>

							<app-footer-navigation [key]="'footer-about'"
							                       class="col-lg-4 mb-10"></app-footer-navigation>

							<app-footer-navigation [key]="'footer-right'"
							                       class="col-lg-4 mb-10"></app-footer-navigation>


						</div>

						<div *ngIf="!dynamicFooterNavigation" class="row">

							<div class="col-lg-4 mb-10">
								<h6 class="mb-5">{{'footer.leftTitle' | translate}}</h6>
								<div class="nav nav-dark flex-column">
									<span *ngIf="contactLink" [innerHTML]="contactLink"></span>
									<span *ngIf="contactEmail" [innerHTML]="contactEmail"></span>
								</div>
							</div>
							<div class="col-lg-4 mb-10">
								<h6 class="mb-5">{{'footer.aboutUsTitle' | translate}}</h6>
								<div class="nav nav-dark flex-column">
									<!--									<a *ngFor="let p of pages" class="nav-link pl-0 pr-5" routerLink="/page/{{p.name}}" routerLinkActive="active">-->
									<!--										{{p.title | chooseTranslation}}-->
									<!--									</a>-->
									<a class="nav-link pl-0 pr-5" routerLink="/page/privacy-policy"
									   routerLinkActive="active">
										{{'page.privacy-policy' | translate}}
									</a>
									<a class="nav-link pl-0 pr-5" routerLink="/page/disclaimer"
									   routerLinkActive="active">
										{{'page.disclaimer' | translate}}
									</a>
								</div>
							</div>
							<div class="col-lg-4 mb-10">
								<h6 class="mb-5">{{'footer.rightTitle' | translate}}</h6>
								<div class="nav nav-dark flex-column">
									<a class="nav-link pl-0 pr-5" routerLink="/home">
										{{'header.Home' | translate}}
									</a>
									<a class="nav-link pl-0 pr-5" routerLink="/job">
										{{'header.Jobs' | translate}}
									</a>
									<a class="nav-link pl-0 pr-5" [routerLink]="shortApplication ? '/job-spontaneous/short' : '/job-spontaneous'">
										{{'header.SpontaneousApplication' | translate}}
									</a>
								</div>
							</div>
						</div>


					</div>
				</div>
				<div class="row mt-5">
					<!-- TODO: copyright, built with love. -->
					<div class="col-lg-10">
						{{copyright}}. All Rights Reserved
						<span (click)="cookie.showBanner(false)" class="mr-5"
						      style="cursor:pointer;">| {{'page.editCookieSettingsBtnShort' | translate}}</span>
					</div>
					<div class="col-lg-2 cuco-copyright">
						Built with love by <a class="kt-link font-weight-bold" href="https://cuco.lu" target="_blank">cuco</a>
						<img class="ml-1 cuco-copyright" src="/assets/media/logos/cuco-logo-bird-dark.svg">
					</div>
				</div>


			</div>
		</div>
	</div>
</div>
