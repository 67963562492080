import {Component} from '@angular/core';

@Component({
	selector: 'met-label',
	styles: [':host { display: contents; }'],
	template: '<label class="form-control-label"><ng-content></ng-content></label>'
})
export class FormLabelComponent {

	constructor() {
	}

}
