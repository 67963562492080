import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthService} from 'src/app/shared/services/auth.service';


@Injectable()
export class JWTInterceptor implements HttpInterceptor {

	excludedURLs = [
		"/api/verify",
		"/api/login",
		"/api/forgot-password",
		"/api/reset-password",
	]

	constructor(private authenticationService: AuthService) {
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		if (request.url.startsWith("/api/") &&
			!request.headers.has('Authorization') &&
			!this.excludedURLs.includes(request.url)) {

			// add authorization header with jwt token if available
			let currentUser = this.authenticationService.getCurrentUser();

			if (currentUser && currentUser.token) {
				request = request.clone({
					setHeaders: {
						Authorization: `Bearer ${currentUser.token}`
					}
				});
			}
		}

		return next.handle(request);
	}
}
