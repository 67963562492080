<div [ngClass]="{'aside-on' : this.layoutService.asideOn}"
     class="aside aside-left d-flex flex-column flex-row-auto aside-fixed" id="kt_aside">

	<div class="brand flex-column-auto" id="kt-aside">
		<img alt="logo" src="/assets/media/logos/logo.png" style="max-width: 90%; max-height: 50px">

		<button (click)="toggleAsideClick()" class="brand-toggle btn btn-sm px-0" id="kt_aside_toggle">
			<span [inlineSVG]="'./assets/media/icons/svg/Angle-double-left.svg'"
			      class="svg-icon svg-icon-xl svg-icon-brand"></span>
		</button>
	</div>

	<div class="aside-menu-wrapper flex-column-fluid" id="kt_aside_menu_wrapper">
		<ng-scrollbar>
			<div #asideMenu
				 [ngStyle]="{'max-height': 'calc(100vh - 80px)', 'position': 'relative'}"
				 class="aside-menu scroll my-4"
				 id="kt_aside_menu">


				<ul class="menu-nav">
					<ng-container [ngTemplateOutlet]="menuListTemplate"></ng-container>
				</ul>

			</div>
		</ng-scrollbar>

	</div>
</div>

<ng-template #menuListTemplate>
	<ng-container *ngFor="let child of menuitems">
		<ng-container *ngIf="child.type === 'section'" [ngTemplateOutletContext]="{ item: child }"
		              [ngTemplateOutlet]="menuItemSectionTemplate"></ng-container>
		<ng-container *ngIf="child.type === 'separator'" [ngTemplateOutletContext]="{ item: child }"
		              [ngTemplateOutlet]="menuItemSeparatorTemplate"></ng-container>
		<ng-container *ngIf="!child.type || child.type === 'item'"
		              [ngTemplateOutletContext]="{ item: child }"
		              [ngTemplateOutlet]="menuItemInnerTemplate"></ng-container>
	</ng-container>
</ng-template>

<ng-template #menuSubmenuTemplate let-item="item" let-parentItem="parentItem">
	<ul class="menu-subnav">
		<ng-container *ngFor="let child of item.children">
			<ng-container *ngIf="child.type === 'section'" [ngTemplateOutletContext]="{ item: child, parentItem: item}"
			              [ngTemplateOutlet]="menuItemSectionTemplate"></ng-container>
			<ng-container *ngIf="child.type === 'separator'"
			              [ngTemplateOutletContext]="{ item: child, parentItem: item }"
			              [ngTemplateOutlet]="menuItemSeparatorTemplate"></ng-container>
			<ng-container *ngIf="(!child.type || child.type === 'item')"
			              [ngTemplateOutletContext]="{ item: child, parentItem: item }"
			              [ngTemplateOutlet]="menuItemInnerTemplate"></ng-container>
		</ng-container>
	</ul>
</ng-template>

<ng-template #menuItemInnerTemplate let-item="item" let-parentItem="parentItem">
	<li [attr.aria-haspopup]="true"
	    [attr.data-ktmenu-dropdown-toggle-class]="item['dropdown-toggle-class']"
	    [attr.data-ktmenu-submenu-mode]="item.mode"
	    [ngClass]="{'menu-item-submenu': item.children}"
	    class="menu-item"
	    data-placement="right"
	    routerLinkActive="menu-item-active menu-item-here menu-item-open">

		<!--		[attr.data-menu-toggle]="getItemAttrSubmenuToggle(item)" -->
		<!--		[ngbTooltip]="item.tooltip"-->


		<!-- if menu item hasn't submenu -->
		<a (click)="toggleAsideClick()" *ngIf="!item.children" [routerLink]="item.url" class="menu-link menu-toggle">
			<ng-container [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"
			              [ngTemplateOutlet]="menuItemTextTemplate"></ng-container>
		</a>

		<!-- if menu item has submenu child  -->
		<a *ngIf="item.children" class="menu-link menu-toggle">
			<ng-container [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"
			              [ngTemplateOutlet]="menuItemTextTemplate"></ng-container>
		</a>

		<!-- if menu item has submenu child then recursively call new menu item component -->
		<div *ngIf="item.children" class="menu-submenu">
			<div *ngIf="item['custom-class'] === 'menu-item--submenu-fullheight'" class="menu-wrapper">
				<!-- wrap submenu to full height -->
				<ng-container [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"
				              [ngTemplateOutlet]="menuSubmenuTemplate"></ng-container>
			</div>
			<!-- normal submenu -->
			<ng-container *ngIf="item['custom-class'] !== 'menu-item--submenu-fullheight'"
			              [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"
			              [ngTemplateOutlet]="menuSubmenuTemplate">
			</ng-container>
		</div>
	</li>

</ng-template>


<ng-template #menuItemTextTemplate let-item="item" let-parentItem="parentItem">
	<!-- if menu item has icon -->
	<i *ngIf="item.icon" class="menu-icon la {{item.icon}}"></i>

	<!-- if menu item using bullet -->
	<i *ngIf="parentItem && parentItem.bullet === 'dot'" class="menu-bullet menu-bullet-dot">
		<span></span>
	</i>
	<i *ngIf="parentItem && parentItem.bullet === 'line'" class="menu-bullet menu-bullet-line">
		<span></span>
	</i>

	<!-- menu item title text -->
	<span class="menu-text">{{item.name | translate}}</span>

	<!-- menu item with badge -->
	<span *ngIf="item.badge" class="menu-badge">
		<span [ngClass]="item.badge.type" class="badge">{{item.badge.value}}</span>
	</span>

	<!-- if menu item has submenu child then put arrow icon -->
	<i *ngIf="item.children" class="menu-arrow"></i>
</ng-template>

<ng-template #menuItemSeparatorTemplate let-item="item" let-parentItem="parentItem">
	<li class="menu-separator"><span></span></li>
</ng-template>

<ng-template #menuItemSectionTemplate let-item="item" let-parentItem="parentItem">
	<li class="menu-section">
		<h4 class="menu-text">{{item.name | translate}}</h4>
		<i class="menu-icon flaticon-more-v2"></i>
	</li>
</ng-template>
