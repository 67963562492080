<div class="form-group validated"> <!-- also make date / whatever configurable -->
	<ng-content></ng-content>
<!--	<input type="text" class="form-control is-valid" readonly  placeholder="Select date"/>-->
<!--	<div class="input-group-append">-->
<!--		<span class="input-group-text">-->
<!--		<i class="la la-calendar-check-o"></i>-->
<!--		</span>-->
<!--	</div>-->
	<ng-content *ngIf="!showErrors" select="met-hint"></ng-content>
	<ng-content *ngIf="showErrors" select="met-error"></ng-content>
</div>

<!--<div>-->
<!--	<ng-content [class.is-valid]="!showErrors"></ng-content>-->
<!--	<ng-content *ngIf="!showErrors" select="app-hint"></ng-content>-->
<!--	<ng-content *ngIf="!showErrors" select="app-error"></ng-content>-->
<!--</div>-->

