import {Injectable} from '@angular/core';
import menuItemsJson from 'src/app/settings.json';

@Injectable({
	providedIn: 'root'
})
export class SettingsService {

	constructor() {
	}

	public get(key : string) {
		return menuItemsJson[key] === undefined ? null : menuItemsJson[key];
	}

}
