import {Pipe, PipeTransform} from '@angular/core';
import {LanguageService} from "../../services/language.service";

@Pipe({
	name: 'chooseTranslation',
	pure: false
})
export class ChooseTranslationPipe implements PipeTransform {

	constructor(private languageService: LanguageService) {
	}

	transform(value: { [key: string]: string }): string {
		return this.languageService.chooseTranslation(value);
	}

}
