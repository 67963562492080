import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../../../shared/services/auth.service";
import {OffsidePanelService} from "../../offside-panel/offside-panel.service";
import {SettingsService} from "../../../../shared/services/settings.service";

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
	shortApplication: boolean = false;

	currentUser: any;

	constructor(private authService: AuthService,
	            public offsidePanelService: OffsidePanelService,
	            public settings: SettingsService) {
	}

	logout() {
		this.authService.logout();
	}

	ngOnInit(): void {
		this.shortApplication = this.settings.get('SHORT_APPLICATION_VERSION');
		this.currentUser = this.authService.getCurrentUser();
	}

}
