import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Location} from "@angular/common";

@Component({
	selector: 'app-forbidden',
	templateUrl: './forbidden.component.html',
	styleUrls: ['./forbidden.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class ForbiddenComponent implements OnInit {

	constructor(public location: Location) {
	}

	ngOnInit(): void {

		/*
	   * Replace all SVG images with inline SVG
	   */

		// TODO when merge this with SSR please provide check on html element! (Do not use element, document etc in SSR)
		// 	  (e.g. 404 page)
/*
		document.querySelectorAll('img.svg').forEach(function (img: any) {
			let imgID = img.id;
			let imgClass = img.className;
			let imgURL = img.src;

			fetch(imgURL).then(function (response) {
				return response.text();
			}).then(function (text) {

				var parser = new DOMParser();
				var xmlDoc = parser.parseFromString(text, "text/xml");

				// Get the SVG tag, ignore the rest
				var svg = xmlDoc.getElementsByTagName('svg')[0];

				// Add replaced image's ID to the new SVG
				if (typeof imgID !== 'undefined') {
					svg.setAttribute('id', imgID);
				}
				// Add replaced image's classes to the new SVG
				if (typeof imgClass !== 'undefined') {
					svg.setAttribute('class', imgClass + ' replaced-svg');
				}

				// Remove any invalid XML tags as per http://validator.w3.org
				svg.removeAttribute('xmlns:a');

				// Check if the viewport is set, if the viewport is not set the SVG wont't scale.
				if (!svg.getAttribute('viewBox') && svg.getAttribute('height') && svg.getAttribute('width')) {
					svg.setAttribute('viewBox', '0 0 ' + svg.getAttribute('height') + ' ' + svg.getAttribute('width'))
				}

				// Replace image with new SVG
				img.parentNode.replaceChild(svg, img);

			});

		});

 */
	}

}
