import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {IsBrowserService} from "../../shared/services/isBrowser.service";

@Component({
	selector: 'app-job-not-found',
	templateUrl: './job-not-found.component.html',
	styleUrls: ['./job-not-found.component.scss',],
	encapsulation: ViewEncapsulation.None

})
export class JobNotFoundComponent implements OnInit {

	constructor(private isBrowserService: IsBrowserService) {
	}

	ngOnInit(): void {
		if (this.isBrowserService.isBrowser) {
			/*
			 * Replace all SVG images with inline SVG
			 */
			/*
			document.querySelectorAll('img.svg').forEach(function (img: any) {
				let imgID = img.id;
				let imgClass = img.className;
				let imgURL = img.src;

				fetch(imgURL).then(function (response) {
					return response.text();
				}).then(function (text) {

					var parser = new DOMParser();
					var xmlDoc = parser.parseFromString(text, "text/xml");

					// Get the SVG tag, ignore the rest
					var svg = xmlDoc.getElementsByTagName('svg')[0];

					// Add replaced image's ID to the new SVG
					if (typeof imgID !== 'undefined') {
						svg.setAttribute('id', imgID);
					}
					// Add replaced image's classes to the new SVG
					if (typeof imgClass !== 'undefined') {
						svg.setAttribute('class', imgClass + ' replaced-svg');
					}

					// Remove any invalid XML tags as per http://validator.w3.org
					svg.removeAttribute('xmlns:a');

					// Check if the viewport is set, if the viewport is not set the SVG wont't scale.
					if (!svg.getAttribute('viewBox') && svg.getAttribute('height') && svg.getAttribute('width')) {
						svg.setAttribute('viewBox', '0 0 ' + svg.getAttribute('height') + ' ' + svg.getAttribute('width'))
					}

					// Replace image with new SVG
					img.parentNode.replaceChild(svg, img);

				});



			});
			// document.querySelector("img").getSVGDocument().getElementById("svgInternalID").setAttribute("fill", "red")
			 */
		}

	}
}
