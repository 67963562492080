import {Component, Input, OnDestroy} from '@angular/core';
import {AbstractControl} from "@angular/forms";
import {Subscription} from "rxjs";

@Component({
	selector: 'met-error',
	// styles: ['.invalid-feedback { display: block; font-size: 0.9rem; }'],
	template: `
		<div class="invalid-feedback">
			<ng-content *ngIf="!_errors"></ng-content>
			<p *ngFor="let e of _errors">{{e}}</p>
		</div>`
})
export class FormErrorComponent implements OnDestroy {
	private _subscription: Subscription = null;
	private _prevControl: AbstractControl = null;

	_errors: string[] = null;

	@Input() set appServerError(c: AbstractControl) {
		if (this._prevControl === c || c == null)
			return;

		if (this._prevControl != null)
			this.unsub();

		this._subscription = c.statusChanges.subscribe(d => {
			if (d != "INVALID")
				return;

			let errors:string[] = c.getError("serverError");
			if (errors)
				this._errors = errors;
			else
				this._errors = null;
		});
	}
	get appServerError(): AbstractControl {
		return this._prevControl;
	}

	constructor() {
	}

	unsub(): void {
		if (this._subscription != null)
			this._subscription.unsubscribe();
	}

	ngOnDestroy(): void {
		this.unsub();
	}



}
