import {Component, OnInit} from '@angular/core';
import {SettingsService} from "../../../shared/services/settings.service";
import {CookieService} from "../../../shared/services/cookie.service";

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
	pages: any[];

	copyright: string;
	//TODO: only temp solution for now, please use Settings API Route for future requests.
	contactLink: string = null;
	contactEmail: string;
	cookieBackground: string = null; /* not implemented for now */
	dynamicFooterNavigation: boolean = false;

	shortApplication: boolean = false;

	constructor(public settings: SettingsService, public cookie: CookieService) {
	}

	ngOnInit(): void {
		this.shortApplication = this.settings.get('SHORT_APPLICATION_VERSION');
		this.dynamicFooterNavigation = this.settings.get("DYNAMIC_FOOTER");
		this.copyright = "© " + this.settings.get("CUSTOMER_NAME") + " " + new Date().getFullYear();

		if (this.settings.get("FOOTER_CONTACT_EMAIL")) {
			let encodedEmail = this.settings.get("FOOTER_CONTACT_EMAIL").replace(/[^]/g, function (e) {
				return "&#" + e.charCodeAt(0) + ";"
			});
			this.contactEmail = '<a rel="nofollow" class="nav-link pl-0 pr-5" href="mailto:' + encodedEmail + '">' + encodedEmail + '</a>';
		}
		let link = this.settings.get("FOOTER_CONTACT_LINK");
		if (link) {
			let linkName = this.settings.get("FOOTER_CONTACT_LINK_NAME") || link;
			this.contactLink = '<a class="nav-link pl-0 pr-5" href="' + link + '" target="_blank">' + linkName + '</a>';
		}
	}
}
