import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';
import {AuthService} from 'src/app/shared/services/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
	constructor(private authenticationService: AuthService, private router: Router) {
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(catchError(err => {

			if (err.status == 401 && request.url != "/verify") {
				this.authenticationService.logout();
			}

			if (err.status === 403) {
				this.router.navigate(['/forbidden'], {replaceUrl: true});
			}

			return throwError(err);
		}));
	}
}

