<div id="toast-container col-12" class="ui-toast-top-center position-relative">
	<div class="row" *ngFor="let toast of snackbarService._toasts">
		<ngb-toast
			[class]="['toast', 'm-2', toast.class]"
			[autohide]="true"
			[delay]="toast.delay || 7000"
			(hidden)="snackbarService.remove(toast)">
			<ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
				<ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
			</ng-template>

			<ng-template #text>
				<div class="toast-title" *ngIf="toast.title">{{toast.title}}</div>
				<div class="toast-message">{{toast.textOrTpl}}</div>
			</ng-template>


			<ul *ngIf="toast.list">
				<li *ngFor="let item of toast.list">{{item}}</li>
			</ul>
		</ngb-toast>
	</div>
</div>
