import {Component, ElementRef, Input, OnInit, Renderer2, SimpleChanges} from '@angular/core';


@Component({
	selector: 'kt-portlet',
	template: '<ng-content></ng-content>',
	styleUrls: [
		'./kt-portlet.component.scss',
		'./mSpinners.css'
	],
	host: {
		class: "card card-custom gutter-b"
	}
})
export class KtPortletComponent implements OnInit {

	@Input() loadingData: any = null;
	@Input() loader: boolean = false;

	constructor(private targetEl: ElementRef, private renderer: Renderer2) {
	}

	ngOnInit() {

		if (this.loader) {
			const loadingContainer = this.renderer.createElement('div');
			this.renderer.setStyle(loadingContainer, 'display', (this.loadingData != null) ? 'flex' : 'none');  //hide if no values are set
			this.renderer.setStyle(loadingContainer, 'justify-content', 'center');
			this.renderer.setStyle(loadingContainer, 'align-items', 'center');
			this.renderer.addClass(loadingContainer, 'loading-container');
			this.renderer.setStyle(loadingContainer, 'position', 'absolute');
			this.renderer.setStyle(loadingContainer, 'top', '0');
			this.renderer.setStyle(loadingContainer, 'background', 'rgba(0,0,0,0.1)');
			this.renderer.setStyle(loadingContainer, 'width', '100%');
			this.renderer.setStyle(loadingContainer, 'height', '100%');
			// custom spinner -- start
			//const spinnerContainer = this.renderer.createElement('div');
			const spinnerContainer = this.renderer.createElement('div');

			this.renderer.addClass(spinnerContainer, 'm-spinner--signal');
			/*
			this.renderer.addClass(spinnerContainer, 'spinner');
			this.renderer.addClass(spinnerContainer, 'spinner-primary');
			this.renderer.addClass(spinnerContainer, 'spinner-lg');
			*/

			const spinnerInnerDiv1 = this.renderer.createElement('div');
			const spinnerInnerDiv2 = this.renderer.createElement('div');
			const spinnerInnerDiv3 = this.renderer.createElement('div');
			this.renderer.appendChild(spinnerContainer, spinnerInnerDiv1);
			this.renderer.appendChild(spinnerContainer, spinnerInnerDiv2);
			this.renderer.appendChild(spinnerContainer, spinnerInnerDiv3);
			this.renderer.appendChild(loadingContainer, spinnerContainer);
			// custom spinner -- end
			this.renderer.appendChild(this.targetEl.nativeElement, loadingContainer);
		}


	}


	ngOnChanges(simpleChanges: SimpleChanges) {

		if (this.loader) {

			if (simpleChanges.loadingData || simpleChanges.loader) {
				const container = this.targetEl.nativeElement;
				const div = container.querySelector('.loading-container');
				if (div) {
					this.renderer.setStyle(div, 'display', 'none');
				}
			}
		}
	}

}
