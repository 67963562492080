import {Directive, ElementRef, HostListener, Input, OnInit, Optional, Self} from '@angular/core';
import {AbstractControl, NgControl} from "@angular/forms";
import {ErrorContainer, ErrorContainerOwner} from "../classes/error-container";

@Directive({
	selector: '[metInput]',
	providers: [{
		provide: ErrorContainerOwner, useExisting: FormInputDirective
	}],
	host: {class: 'form-control'},
})
export class FormInputDirective implements OnInit {

	errorContainer: ErrorContainer;

	/**
	 * The control to be used for marking stuff like validity
	 * This is useful if you cannot set the formControl directive for a metInput (e.g. bsDatepicker is buggy with formControl)
	 */
	@Input() metInputControl: AbstractControl;

	get control(): AbstractControl | null {
		return this.ngControl ? this.ngControl.control : this.metInputControl;
	}

	@HostListener('blur')
	blur() {
		if (this.control)
			this.control.updateValueAndValidity();
	}

	get value() {
		return this._elementRef.nativeElement.value;
	}

	constructor(protected _elementRef: ElementRef<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
	            @Self() @Optional() public ngControl: NgControl) {
	}

	ngOnInit(): void {
		if (!!this.ngControl)
			this.errorContainer = new ErrorContainer(this._elementRef, this.ngControl);
	}
}
