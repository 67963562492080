<div #dropdown="ngbDropdown" autoClose="outside" class="dropdown dropdown-fluid" ngbDropdown placement="bottom-right">
	<div class="topbar-item" ngbDropdownToggle>
		<div class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1">
			<img alt=""
			     class="h-20px w-20px rounded-sm" src="/assets/media/flags/{{countryToFileName(languageService.getSiteLanguageShort())}}">
		</div>
	</div>

	<div class="dropdown-menu p-0 m-0 dropdown-menu-anim-up dropdown-menu-sm dropdown-menu-right"
	     ngbDropdownMenu>
		<ul class="navi navi-hover py-4">
			<li *ngFor="let lang of systemLanguages" class="navi-item">
				<a (click)="changeLanguage(lang); dropdown.close()" class="navi-link" style="cursor: pointer;">
					<span class="symbol symbol-20 mr-3">
						<img
							alt=""
							src="/assets/media/flags/{{countryToFileName(lang.short)}}">
					</span>
					<span class="navi-text">{{lang.localeName[lang.short] | titlecase}}</span>
				</a>
			</li>
		</ul>
	</div>
</div>
